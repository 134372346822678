import React from 'react';
import './CalendarPopup.css';
import dayjs from 'dayjs';
import { format } from 'date-fns';

// Function to format the date
const formatDate = (isoString) => {
  return dayjs(isoString).format('DD/MM/YY');
};

// Function to format the staff name
const formatStaffName = (firstName, lastName) => {
  return `${firstName || ''} ${lastName || ''}`.trim();
};

// Function to render staff details
const renderStaffDetails = (staff) => (
  <div>
    <div className="popup-detail">
      <strong>Role:</strong> {staff[0].role || 'No role available'}
    </div>
    <div className="popup-detail">
      <strong>Hrly rate:</strong> {staff[0].hourlyPayRate || 'No rate available'}
    </div>
  </div>
);

// Function to render day details
const renderDayDetails = (dayDetails) => (
  <div>
    <div className="popup-detail">
      <strong>Day:</strong> {dayDetails[0].dayOfWeek || 'No day available'}
    </div>
    <div className="popup-detail">
      <strong>Start/end:</strong> {`${dayDetails[0].startTime || ''} - ${dayDetails[0].endTime || ''}`}
    </div>
    <div className="popup-detail">
    <strong>Planned hrs on site:</strong> 
{dayDetails[0].startTime && dayDetails[0].endTime ? 
    ((
        (new Date(`1970-01-01T${dayDetails[0].endTime}:00`) - new Date(`1970-01-01T${dayDetails[0].startTime}:00`)) / 3600000 // Difference in hours
        - dayDetails[0].breakLength
    ).toFixed(1)) : 'n/a'}
    </div>
    <div className="popup-detail">
      <strong>Break length (hrs):</strong> {dayDetails[0].breakLength || 'No break length available'}
    </div>
  </div>
);

// Function to render log event details
const renderLogEventDetails = (logEvent) => (
  <div>
    <div className="popup-detail">
      <strong>Actual login:</strong> {logEvent[0].actual_login ? format(new Date(logEvent[0].actual_login), 'dd/MM HH:mm') : 'No login record available'}
    </div>
    <div className="popup-detail">
      <strong>Hrs on site:</strong> {logEvent[0].hoursonsite || '-'}
    </div>
    <div className="popup-detail">
       <strong>Login message:</strong> {logEvent[0].login_message || '-'}
    </div>
    <div className="popup-detail">
      <strong>Login comment:</strong> {logEvent[0].login_comment || '-'}
    </div>
 
  </div>
);

// Function to render holiday details
const renderHolidayDetails = (holiday) => {
  if (!holiday[0] || !holiday[0].start) {
    // If holiday[0] or holiday[0].start is empty, return null (i.e., render nothing)
    return null;
  }

  return (
    <div>
      <div className="popup-detail">
        <strong>Start:</strong> {formatDate(holiday[0].start) || 'No start date available'}
      </div>
      <div className="popup-detail">
        <strong>End:</strong> {formatDate(holiday[0].end) || 'No end date available'}
      </div>
      <div className="popup-detail">
        <strong>Status:</strong> {holiday[0].status || 'No status available'}
      </div>
    </div>
  );
};

// Function to render sickness details
const renderSicknessDetails = (sickness) => {
  if (!sickness[0] || !sickness[0].date) {
    // If sickness[0] or sickness[0].date is empty, return null (i.e., render nothing)
    return null;
  }

  return (
    <div>
      <div className="popup-detail">
        <strong>Date:</strong> {formatDate(sickness[0].date) || 'No date available'}
      </div>
      <div className="popup-detail">
        <strong>Days away:</strong> {sickness[0].daysAway || 'No days away information available'}
      </div>
      <div className="popup-detail">
        <strong>Status:</strong> {sickness[0].status || 'No status available'}
      </div>
    </div>
  );
};

const CalendarPopup = ({ details, onClose }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          &times;
        </button>
        <div className="popup-body">
          {/* Left Section */}
          <div className="popup-section left">
            <div className="popup-panel upper">
              {details.staff ? renderStaffDetails(details.staff) : 'No staff details available'}
            </div>
            <div className="popup-panel lower">
              {details.dayDetails ? renderDayDetails(details.dayDetails) : 'No day details available'}
            </div>
          </div>

          {/* Middle Section */}
          <div className="popup-section middle">
            <div className="popup-panel upper">
              {details.logEvent ? renderLogEventDetails(details.logEvent) : 'No log entries available'}
            </div>
            <div className="popup-panel lower">
              {details.logEvent && (
                <>
                    <div className="popup-detail">
      <strong>Actual logout:</strong> {details.logEvent[0].actual_logout ? format(new Date(details.logEvent[0].actual_logout), 'dd/MM HH:mm') : '-'}
    </div>

                  <div className="popup-detail">
                    <strong>Overtime available:</strong> {details.logEvent[0]?.overtime_available || '-'}
                  </div>
                  <div className="popup-detail">
                    <strong>Overtime requested:</strong> {details.logEvent[0]?.overtimerequested || '-'}
                  </div>
                  <div className="popup-detail">
      <strong>Logout message:</strong> {details.logEvent[0].logout_message || '-'}
    </div>
    <div className="popup-detail">
      <strong>Logout comment:</strong> {details.logEvent[0].logout_comment || '-'}
    </div>
                </>
              )}
            </div>
          </div>

          {/* Right Section */}
          <div className="popup-section right">
            <div className="popup-panel upper">
              {details.holiday ? renderHolidayDetails(details.holiday) : 'No holiday entries available'}
            </div>
            <div className="popup-panel lower">
              {details.sickness ? renderSicknessDetails(details.sickness) : 'No sickness entries available'}
            </div>
          </div>

          <div className="popup-separator vertical-separator"></div>
        </div>
      </div>
    </div>
  );
};

export default CalendarPopup;
