import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './Settings.css';
import { AuthContext } from './AuthContext';
import { FaTrash } from 'react-icons/fa'; // Import dustbin icon
import Staff from './Staff';
import { v4 as uuidv4 } from 'uuid';
const Settings = () => {
  const { organisationId } = useContext(AuthContext); // Access organisationId
  const [organisationDetails, setOrganisationDetails] = useState([]);

  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [roles, setRoles] = useState([]);
  const rolesRef = useRef([]);
  const [gridData, setGridData] = useState({});
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [validated, setValidated] = useState(false);
  const [managerEmail, setManagerEmail] = useState('');  
  const [name, setName] = useState('');  
  const [ringfence, setRingFence] = useState(0);  
  const [graceperiod, setGracePeriod] = useState(0);  
  const [startDate, setStartDate] = useState(new Date());  // Initialize startDate as a Date object

    const [showAddDialog, setShowAddDialog] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');
   const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);

  const [successMessage, setSuccessMessage] = useState('');
   const [isSaveEnabled, setIsSaveEnabled] = useState(false); 
  const [paycycle, setPaycycle] = useState('Monthly');  // New state for paycycle

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchLocations(organisationId);
        await fetchRoles();
        await fetchWorkingLimits();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchOrganisationDetails(organisationId);
    fetchData();
  }, [selectedLocationId]);



  const fetchOrganisationDetails = async (orgId) => {
    try {
      const response = await axios.get(`https://heuristic-cray.194-76-27-167.plesk.page/api/organisations?org=${orgId}`);
      
      // No need for response.json() since axios handles it
      const data = response.data; 
      const formattedHolstart = data.holstart ? formatDateToInput(data.holstart) : '1900-01-01';

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        setOrganisationDetails(prevDetails => ({
          ...prevDetails,
          ...data,
          holstart: formattedHolstart, // Use the formatted date
        }));
      }
    } catch (error) {
      console.error("Error fetching organisation details:", error);
      // Handle error (e.g., set an error state or show a message)
    }
  };


  const formatDateToInput = (isoDate) => {
    const date = new Date(isoDate); // Create a Date object from the ISO date string
    const year = date.getFullYear(); // Get the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed, hence +1) and pad to 2 digits
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad to 2 digits
    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
  };
  
   

  const handleAddLocationClick = () => setShowAddDialog(true);
  const handleCancelAdd = () => {
    setShowAddDialog(false);
    setNewLocationName('');
  };
  const handleSaveLocation = async () => {
    try {
      const newLocation = { 
        name: newLocationName, 
        organisation: organisationId,
        paycycle: organisationDetails.paycycle,
        graceperiod: organisationDetails.graceperiod,
        ringfence: organisationDetails.ringfence,
        startdate: organisationDetails.startdate,
        guid: uuidv4()

    
      };
      await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/locations', newLocation);
      setShowAddDialog(false);
      setNewLocationName('');
      fetchLocations(organisationId);
    } catch (error) {
      console.error('Error creating location:', error);
    }
  };

  const handleDeleteClick = (location) => {
    setLocationToDelete(location);
    setIsDeleteDialogVisible(true);
  };
  const handleConfirmDelete = async () => {
    try {
      const updatedLocation = {
        ...locationToDelete,
        organisation: `deleted${organisationId}`
      };
      await axios.post(`/api/locations/${locationToDelete._id}`, updatedLocation);
      setIsDeleteDialogVisible(false);
      setLocationToDelete(null);
      fetchLocations(organisationId);
    } catch (error) {
      console.error('Error deleting location:', error);
    }
  };


  const fetchLocations = async (orgId) => {
    try {
      const locationResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations');
      const filteredLocations = locationResponse.data.filter(location => location.organisation === orgId);
      setLocations(filteredLocations); // Set t
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
   // setSelectedLocationId('');
  };

  const handleSaveWorkingPatterns = async () => {
    // Convert gridData from a key-value pair object to a JSON-style array
    const jsonDataGrid = Object.keys(gridData).map(key => {
        const [role, day] = key.split('-'); // Split the key into role and day
        return { role, day, value: gridData[key] }; // Return the new structure
    });

    // Convert gridData from an object to an array of values
    let dd = Object.values(jsonDataGrid);
    const payload = {
        locationId: selectedLocationId, // Pass selectedLocationId as locationId
        data: jsonDataGrid // Use the newly created jsonDataGrid
    };

    try {
        await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/workinglimits_update', payload);
        console.log('Changes saved successfully:', payload);
    } catch (error) {
        console.error('Error saving changes:', error);
    }
};



  const handleInputChange = (role, day, value) => {
    const key = `${role}-${day}`;
    const numericValue = Number(value);
    setGridData(prev => ({
      ...prev,
      [key]: isNaN(numericValue) ? 0 : numericValue
    }));
    setIsSaveEnabled(true);  // Enable save button when grid data changes
  };

  const validateInput = (event) => {
    const value = event.target.value;
    if (!/^\d*$/.test(value)) {
      event.target.value = '';
    }
  };


  const fetchWorkingLimits = async () => {
    if (selectedLocationId) {
      try {
        // Define parameters for the API request
        const params = { locationId: selectedLocationId };
        const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/workinglimits', { params });
        const workingLimitData = response.data; 
        if (workingLimitData && workingLimitData.length > 0) {
          // Extract the 'data' array from the first element of the response
          const extractedData = workingLimitData[0].data;
      
          // Convert the extracted 'data' array into a key-pair array
          const newGridData = {};
          extractedData.forEach(item => {
              const key = `${item.role}-${item.day}`; // Create a key based on role and day
              newGridData[key] = item.value; // Assign the value to the key, ignoring _id
          });
      
          // Set the gridData with the newly created key-pair array
          setGridData(newGridData);
        } else {
          // If no data found, create a blank gridData object
          const newGridData = {};
          rolesRef.current.forEach(role => {
            ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].forEach(day => {
              newGridData[`${role.role}-${day}`] = 0; // Initialize all entries to 0
            });
          });
  
          // Prepare the data structure for the insert
          const workingLimitsToInsert = Object.keys(newGridData).map(key => {
            const [role, day] = key.split('-');
            return {
              role, // Include role
              day ,   //, // Include day
              value: newGridData[key] // Use value
            };
          });
  
          // Insert the blank array into the database, passing locationId and data as parameters
          await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/workinglimit_insert', {
            locationId: selectedLocationId, // Pass locationId in the request body
            data: workingLimitsToInsert // Pass the working limits data array
          });
  
    //      console.log('Blank working limits inserted successfully:', workingLimitsToInsert);
          // Optionally set gridData to the new blank data
  
          const keypairDataGrid = {};

          workingLimitsToInsert.forEach(item => {
            const key = Object.keys(item)
              .filter(k => k !== 'value')  // Exclude the 'value' field from the key
              .map(k => item[k])           // Get the values of each field
              .join('-');                  // Concatenate them with hyphen
          
            keypairDataGrid[key] = item.value; // Assign the value to the generated key
          });
          setGridData(keypairDataGrid);
          
        }
      } catch (error) {
        console.error('Error fetching working limits:', error);
      }
    }
  };
  

  const fetchRoles = async () => {
    try {
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles');
      setRoles(response.data);
      rolesRef.current = response.data;
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleValidateLocation = () => {
    setValidated(prevValidated => {
      const newValidated = !prevValidated;
      setIsSaveEnabled(true);
      return newValidated;
    });
  };

  const handleLocationClick = async (id) => {
    setSelectedLocationId(id);
    const location = locations.find(loc => loc._id === id);
    if (location) {
       setLatitude(location.latitude);
      setLongitude(location.longitude);
      setValidated(location.validated);
      setManagerEmail(location.manager_email);
      setName(location.name);
      setGracePeriod(location.graceperiod);
      setRingFence(location.ringfence);
      setPaycycle(location.paycycle);
      setStartDate(location.startdate ? new Date(location.startdate) : null);

    }
  };

 
////
 
  const handleSaveChanges = async () => {

    const payload = {
      _id: selectedLocationId,
      validated,
      manager_email: managerEmail,
      name: name,
     startdate: startDate, // ? startDate.toISOString().split('T')[0] : '', // Convert to YYYY-MM-DD
      graceperiod: graceperiod,
      ringfence: ringfence,
      paycycle: paycycle

    };

    try {
      await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/location_update_byId', payload);
     // console.log('Changes saved successfully:', payload);
      setIsSaveEnabled(false);
 
      // Set the success message and clear it after a few seconds
      setSuccessMessage('Record saved successfully');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000); // Message disappears after 3 seconds

    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

        
////

  const handleGracePeriodChange = (e) => {
    setGracePeriod(parseFloat(e.target.value) || 0); // Use parseInt if you need an integer
    setIsSaveEnabled(true);
  };

  const handleRingFenceChange = (e) => {
    setRingFence(parseFloat(e.target.value) || 0);
    setIsSaveEnabled(true);
  };


  const handleNameChange = (e) => {
    setName(e.target.value);
    setIsSaveEnabled(true);
  };
  

  const handleManagerEmailChange = (e) => {
    setManagerEmail(e.target.value);
    setIsSaveEnabled(true);
  };

  const handlePaycycleChange = (e) => {
    setPaycycle(e.target.value);
    setIsSaveEnabled(true);
  };

  return (
    <div className="settings-container" style={{ display: 'flex', width: '100%', gap: '20px' }}>
       <div className="left-panel" style={{ width: '20%' }}>
        <h2>Locations</h2>
        <button onClick={handleAddLocationClick}>Add new location</button>
        
         
        <div className="locations-grid">
          {locations.map((location, index) => (
            <div
              key={location._id}
              className={`location-item ${selectedLocationId === location._id ? 'selected' : ''} ${index % 2 === 0 ? 'zebra-odd' : 'zebra-even'}`}
 //             onClick={() => setSelectedLocationId(location._id)}
              onClick={() => handleLocationClick(location._id)} // Updated to call handleLocationClick
 
            >
              {location.name}
              <FaTrash onClick={() => handleDeleteClick(location)} className="delete-icon" />
            </div>
          ))}
        </div>
      </div>

      {/* Add Location Dialog */}
      {showAddDialog && (
        <div className="dialog-overlay">
          <div className="dialog">
            <h3>Add New Location</h3>
            <input
              type="text"
              value={newLocationName}
              onChange={(e) => setNewLocationName(e.target.value)}
              placeholder="Enter location name"
            />
            <button onClick={handleSaveLocation}>Save</button>
            <button onClick={handleCancelAdd}>Cancel</button>
          </div>
        </div>
      )}

      {/* Confirm Delete Dialog */}
      {isDeleteDialogVisible && (
        <div className="dialog-overlay">
          <div className="dialog">
            <p>Are you sure you want to delete this location?</p>
            <button onClick={handleConfirmDelete}>Yes</button>
            <button onClick={() => setIsDeleteDialogVisible(false)}>No</button>
          </div>
        </div>
      )}


      <div className="middle-panel" style={{ display: 'block', width: '35%', height: 'auto' }}>
        <h2>Working Limits</h2>
        <button onClick={handleSaveWorkingPatterns} disabled={!isSaveEnabled}>Save Changes</button>
        <div style={{ marginTop: '20px' }}>
          <table style={{ width: '75%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>Roles</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
              </tr>
            </thead>
            <tbody>
              {roles.map(role => (
                <tr key={role._id}>
                  <td>{role.role}</td>
                  {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                    <td key={`${role._id}-${day}`}>
                      <input
                        type="text"
                        style={{ width: '1cm' }}
                        value={gridData[`${role.role}-${day}`] || 0}
                        onChange={e => handleInputChange(role.role, day, e.target.value)}
                        onBlur={validateInput}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>


      <div className="right-panel" style={{ display: 'block', width: '45%', height: 'Auto' }}>
        <h2>Map view</h2>
        <div className="map-panel">
          {latitude && longitude ? (
            <LoadScript googleMapsApiKey="AIzaSyDSOXhWqgQRNvcZaF0FaOSKEGZO48wISSU">
              <GoogleMap
                mapContainerStyle={{ height: '200px', width: '100%' }}
                center={{ lat: latitude, lng: longitude }}
                zoom={15}
              >
                <Marker position={{ lat: latitude, lng: longitude }} />
              </GoogleMap>
            </LoadScript>
          ) : (
            <p>Select a location to view its map.</p>
          )}
        </div>

        <div style={{ marginTop: '30px' }}>
        <label style={{ display: 'flex', alignItems: 'center' }}>
           Validated
            <input
              type="checkbox"
              checked={validated}
              onChange={handleValidateLocation}
              style={{ marginRight: '5px' }} // Adjust the spacing as needed
            />

          </label>

          <div style={{ marginTop: '20px' }}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              style={{ marginLeft: '10px' }}
            />
          </div>


          <div style={{ marginTop: '20px' }}>
            <label htmlFor="managerEmail">Manager Email:</label>
            <input
              type="email"
              id="managerEmail"
              value={managerEmail}
              onChange={handleManagerEmailChange}
              style={{ marginLeft: '10px' }}
            />
          </div>

 {/* Pay cycle radio buttons */}
<div style={{ marginTop: '10px' }}>
  <label>Pay Cycle:</label>
  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
    <label style={{ marginRight: '10px' }}>
      <input
        type="radio"
        name="paycycle"
        value="Monthly"
        checked={paycycle === 'Monthly'}
        onChange={handlePaycycleChange}
      />
      Monthly
    </label>
    <label>
      <input
        type="radio"
        name="paycycle"
        value="Fortnightly"
        checked={paycycle === 'Fortnightly'}
        onChange={handlePaycycleChange}
      />
      Fortnightly
      </label>
    <label>
      <input
        type="radio"
        name="paycycle"
        value="Weekly"
        checked={paycycle === 'Weekly'}
        onChange={handlePaycycleChange}
      />
      Weekly
    </label>
  </div>
</div>


 {/* New input for Grace Period */}
 <div className="form-field" style={{ paddingTop: '20px' }}>

    <label htmlFor="gracePeriod">Grace period</label>
    <input
      type="number"
      id="gracePeriod"
      value={graceperiod}
      onChange={handleGracePeriodChange}
      required
      min="0"
    />
  </div>

    {/* New input for Ring Fence (metres)     value={startDate.toISOString().split('T')[0]}   */}
    <div className="form-field">
    <label htmlFor="ringFence">Ring fence (metres)</label>
    <input
      type="number"
      id="ringFence"
      value={ringfence}
      onChange={handleRingFenceChange}
      required
      min="0"
    />
  </div>

 

  <div className="form-field">
  <label htmlFor="startDate">Paycycle start day</label>
  <input
    type="date"
    value={startDate ? formatDateToInput(startDate) : ''} // Use the formatted date string for display
    onChange={(e) => {
      setStartDate(new Date(e.target.value)); // Convert the input value back to Date
      setIsSaveEnabled(true); // Enable the save button
    }}
  />
</div>

 
  


          <button
            onClick={handleSaveChanges}
            style={{ marginTop: '10px' }}
            disabled={!isSaveEnabled}
          >
            Save
          </button>
          
      {successMessage && (
        <div style={{ color: 'green', marginTop: '10px' }}>
          {successMessage}
        </div>
      )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
