import React, {useRef, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import './Sops.css';
import { AuthContext } from './AuthContext';
import { format } from 'date-fns';
import SopVersions from './SopVersions';

const Sops = () => {
  const { organisationId } = useContext(AuthContext);

  // State variables
  const [showSopVersionsPopup, setShowSopVersionsPopup] = useState(false);
const [selectedSopItem, setSelectedSopItem] = useState(null);
const staffMembers = useRef([]);

  const [sops, setSops] = useState([]);
  const [staffRoles, setStaffRoles] = useState([]);
  const [selectedSop, setSelectedSop] = useState(null);
  const [selectedStaffMember, setSelectedStaffMember] = useState(null);
  const [sopReading, setSopReading] = useState([]);
  const [readingHistory, setReadingHistory] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [sortOrder, setSortOrder] = useState({ field: 'name', ascending: true });
  const [newSopName, setNewSopName] = useState(''); // New SOP name input
  const [nameInput, setNameInput] = useState('');
  const [activeInput, setActiveInput] = useState(false);
  const [isChanged, setIsChanged] = useState(false); // Tracks if any input is made

  // Fetch SOPs
  const fetchSops = async () => {
    try {
      const response = await axios.get(`https://heuristic-cray.194-76-27-167.plesk.page/api/sops?organisation_id=${organisationId}`);
      setSops(response.data);
    } catch (error) {
      console.error('Error fetching SOPs:', error);
    }
  };

  // Fetch Roles
  const fetchRoles = async () => {
    try {
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles');
      setStaffRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };




 
  const fetchStaffMembers = async (orgId) => {
    try {
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?active=true&organisation_id=${orgId}`;
      const staffResponse = await axios.get(staffUrl); // Fetch the staff data
      // Filter the staff members based on organisation_id
      staffMembers.current = staffResponse.data.filter(staff => staff.organisation_id === orgId);
         //   //////console.log('Successfully fetched staff members:', staffResponse.data);
    } catch (error) {
      //console.error('Failed to load staff members:', error);
    }
  };

  // Fetch SOP Reading History for selected staff member
 
 
  const fetchReadings = async () => {
    try {
      // Fetch existing readings for the selected SOP
      const existingReadingsResponse = await axios.get(
        `https://heuristic-cray.194-76-27-167.plesk.page/api/sop_readings?sop_id=${selectedSop._id}`
      );
      const existingReadings = existingReadingsResponse.data;
  
      // Identify staff members who haven't read the current SOP version and whose role matches one of the selectedSop.roles
      const missingEntries = staffMembers.current // Access the data stored in the useRef
        .filter(
          (staff) =>
            selectedSop.roles.includes(staff.role) && // Filter by role matching selectedSop.roles
            !existingReadings.some(
              (reading) =>
                reading.staff_id === staff._id && reading.sop_uid === selectedSop.current_uid
            )
        )
        .map((staff) => ({
          staff_id: staff._id,
          staff_name: staff.firstName + ' '+ staff.lastName,
          sop_id: selectedSop._id,
          sop_uid: selectedSop.current_uid,
          sop_name: selectedSop.name, // Use 'name' from selectedSop
          created: new Date().toISOString(),
        }));
  
      // Save missing readings to the database
      if (missingEntries.length > 0) {
        await axios.post(
          `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sop_reading`,
          missingEntries
        );
      }
  
      // Combine existing and newly created readings
      setSopReading([...existingReadings, ...missingEntries]);
    } catch (error) {
      console.error("Error fetching or updating SOP readings:", error);
    }
  };
  
 
  // Fetch SOP Reading History for selected staff member


  const fetchReadingHistory = async (sopId, staffId) => {
   
   
   
    try {
      const response = await axios.get(`https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sop_reading_history?sop_id=${sopId}&staff_id=${staffId}`);
      setReadingHistory(response.data);
    } catch (error) {
      console.error('Error fetching SOP reading history:', error);
    }
  };

  useEffect(() => {
    fetchStaffMembers(organisationId);
    fetchSops();
    fetchRoles();
  }, []);

  const sortedSops = [...sops].sort((a, b) => {
    if (a.active === b.active) {
      const nameA = a.name || ""; // Default to empty string if undefined
      const nameB = b.name || ""; // Default to empty string if undefined
      return nameA.localeCompare(nameB);
    }
    return a.active ? -1 : 1;
  });

  // Opens edit dialog for SOP
  const openEditDialog = (sop) => {
 //   setSelectedSop(sop);
    setNameInput('');  // Set current name in input
    setActiveInput(sop.active || false);  // Set current active status
    setIsChanged(false);  // Reset change tracker
    setShowDialog(true);
  };

  const newSop = {
     title: "",
    active: true, // Default value for 'active'
    // other properties
  };

 

  const setUpEdit = (sop) => {
    setSelectedSop(sop);
    setNameInput(sop.name || '');  // Set current name in input
    setActiveInput(sop.active || false);  // Set current active status
    setIsChanged(false);  // Reset change tracker
  };
  
  useEffect(() => {
    if (selectedSop && selectedSop._id) {
      fetchReadings();  // Call fetchReadings when selectedSop is updated
    }
  }, [selectedSop]);  // Dependency array ensures this effect runs whenever selectedSop changes
  
  // Handles role checkbox toggle
  const handleRoleToggle = (roleName) => {
    const updatedRoles = selectedSop.roles.includes(roleName)
      ? selectedSop.roles.filter((role) => role !== roleName)
      : [...selectedSop.roles, roleName];
    setSelectedSop({ ...selectedSop, roles: updatedRoles });
  };

  // Toggles sorting by column in SOP Reading panel
  const toggleSort = (field) => {
    const isAscending = sortOrder.field === field ? !sortOrder.ascending : true;
    setSortOrder({ field, ascending: isAscending });
    const sortedReading = [...sopReading].sort((a, b) => {
      const compareA = field === 'name' ? a.staff_name : new Date(a.readon);
      const compareB = field === 'name' ? b.staff_name : new Date(b.readon);
      return isAscending ? (compareA > compareB ? 1 : -1) : (compareA < compareB ? 1 : -1);
    });
    setSopReading(sortedReading);
  };

  // Save the updated SOP (Name and Active)
  const saveUpdatedSop = async () => {
    try {
      const updatedSop = {
        _id: selectedSop._id,
        name: nameInput,
        active: activeInput,
        roles: selectedSop.roles, // Keep the roles unchanged
      };
      await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/sop_update', updatedSop);
      setShowDialog(false); // Close dialog
      fetchSops(); // Refresh SOP list
    } catch (error) {
      console.error('Error updating SOP:', error);
    }
  };

  // Detect changes in input fields to enable the save button
  const handleInputChange = () => {
    setIsChanged(nameInput !== selectedSop.name || activeInput !== selectedSop.active);
  };

  // Fetches SOP versions
  const fetchSopVersions = async (sopId) => {
    try {
      const response = await axios.get(`https://heuristic-cray.194-76-27-167.plesk.page/api/sop_versions?sop_id=${sopId}`);
      // Logic to open version popup goes here
      console.log(response.data); // Placeholder: You can handle the version data here
    } catch (error) {
      console.error('Error fetching SOP versions:', error);
    }
  };

// Save the new SOP
const saveNewSop = async () => {
  try {
    const newSop = {
      name: newSopName,
      active: true,
      organisation_id: organisationId
    };
    await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/sops', newSop);
    setShowDialog(false); // Close dialog
    fetchSops(); // Refresh SOP list
  } catch (error) {
    console.error('Error adding new SOP:', error);
  }
};



  return (
    <div className="sop-management">
      <h1>SOP Management</h1>

      {/* Container for all panels */}
      <div className="sop-container">
        {/* Left Panel - Current SOP Titles */}
        <div className="panel current-sops">
          <h2>Current SOP Titles</h2>
          <button onClick={() => openEditDialog(newSop)}>Add New SOP</button>
          <ul>
            {sortedSops.map((sop) => (
              <li
                key={sop._id}
                className={`sop-item ${sop.active ? '' : 'inactive'}`}
                onClick={() =>  setUpEdit(sop)}
              >
                {sop.name}
              
                <button 
        className="versions-button" 
        onClick={(e) => { 
          e.stopPropagation(); 
          setSelectedSopItem(sop); // Set the selected SOP item
          setShowSopVersionsPopup(true); // Open the SopVersions popup
        }}
      >
        V
      </button>

              </li>
            ))}
          </ul>
        </div>

        {/* Second Panel - Name, Active (checkbox), Roles */}
        <div className="panel roles">
          <h2>Edit SOP</h2>
          <div>
            <label>Name:</label>
            <input
              type="text"
              value={nameInput}
              onChange={(e) => {
                setNameInput(e.target.value);
                handleInputChange();
              }}
            />
          </div>
          <div>
            <label>Active:</label>
            <input
              type="checkbox"
              checked={activeInput}
              onChange={(e) => {
                setActiveInput(e.target.checked);
                handleInputChange();
              }}
            />
          </div>

          <h3>Roles</h3>
          <div className="roles-list">
            {staffRoles.map((role) => (
              <label key={role._id} className="role-checkbox">
                <input
                  type="checkbox"
                  checked={selectedSop && selectedSop.roles.includes(role.role)}
                  onChange={() => handleRoleToggle(role.role)}
                />
                {role.role}
              </label>
            ))}
          </div>

          {/* Save Button */}
          <button
            onClick={saveUpdatedSop}
            disabled={!isChanged} // Disable if no changes are made
          >
            Save
          </button>
        </div>

        {/* Third Panel - SOP Reading */}
        <div className="panel sop-reading">
          <h2>SOP Reading</h2>
          <table>
            <thead>
              <tr>
                <th>Staff Name <button onClick={() => toggleSort('name')}>Sort</button></th>
                <th>Read On <button onClick={() => toggleSort('readon')}>Sort</button></th>
              </tr>
            </thead>
            <tbody>
              {sopReading.map((entry) => (
                <tr
                  key={entry.staff_id}
                  onClick={() => setSelectedStaffMember(entry)}
                  className={`sop-reading-entry ${entry.readon ? 'read' : 'unread'}`}
                >
                  <td>{entry.staff_name}</td>
                  <td>{entry.readon ? format(new Date(entry.readon), 'yyyy-MM-dd HH:mm') : 'Not Read'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* SOP Dialog (Edit or Add New) */}
      {showDialog && (
        <div className="sop-dialog">
          <div className="dialog-content">
            <h3>{selectedSop ? 'Edit SOP' : 'New SOP'}</h3>
            <input
              type="text"
              value={newSopName}
              onChange={(e) => setNewSopName(e.target.value)}
              placeholder="Enter SOP name"
            />
            <button onClick={saveNewSop}>Save</button>
            <button onClick={() => setShowDialog(false)}>Cancel</button>
          </div>
        </div>
      )}
      {showSopVersionsPopup && selectedSopItem && (
  <SopVersions
    sopItem={selectedSopItem}
    onClose={() => {
      setShowSopVersionsPopup(false); // Close the popup
      fetchSops(); // Re-fetch the SOPs
    }}    
  />
)}

    </div>
  );
};

export default Sops;
