import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SopVersions.css';

// Helper function to generate a GUID
function generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// Helper function to format the date in 'dd/mm/yy hh:mm' format
function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(2); // Get last two digits of the year
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export default function SopVersions({ sopItem, onClose }) {
  const [versions, setVersions] = useState(
    [...sopItem.versions].sort((a, b) => new Date(b.date) - new Date(a.date))
  );
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileData, setSelectedFileData] = useState(null); // State for file content dialog

  // Set the `current_uid` on the root element
  useEffect(() => {
    if (versions.length > 0) {
      const mostRecentUid = versions[0].uid;
      document.documentElement.setAttribute('current_uid', mostRecentUid);
    }
  }, [versions]);

  const handleUploadClick = () => setShowUploadDialog(true);
  const handleCancelClick = () => setShowUploadDialog(false);

  const handleFileChange = (e) => setSelectedFile(e.target.files[0]);

  const handleUpload = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileData = event.target.result; // Base64 encoded file data

        const newVersion = {
          date: new Date().toISOString(),
          filename: selectedFile.name,
          uid: generateGUID(),
          filedata: fileData, // Include the file data
        };

        // Update the versions array in state
        const updatedVersions = [newVersion, ...versions];
        setVersions(updatedVersions);

        // Prepare the payload for backend
        const updatedSopItem = {
          ...sopItem,
          versions: updatedVersions,
        };

        // API call to update the SOP data
        try {
          const response = await axios.post(
            'https://heuristic-cray.194-76-27-167.plesk.page/api/sop_update',
            {
              _id: updatedSopItem._id,
              ...updatedSopItem,
            }
          );
          console.log('SOP updated successfully', response.data);
        } catch (error) {
          console.error('Error updating SOP:', error);
        }

        // Reset the file input and close the upload dialog
        setSelectedFile(null);
        setShowUploadDialog(false);
      };

      // Read the file as a Base64 string
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleVersionClick = (fileData) => {
    if (fileData.startsWith('data:')) {
      const base64Data = fileData.split(',')[1]; // Extract Base64 content
      const decodedContent = atob(base64Data); // Decode to string
      setSelectedFileData(decodedContent); // Show dialog with file content
    } else {
      alert('Unsupported file format or missing file data.');
    }
  };

  const handleDownloadClick = (fileData, filename) => {
    if (fileData) {
      const link = document.createElement('a');
      link.href = fileData; // Base64 data URL
      link.download = filename; // Suggest filename
      link.click();
    } else {
      alert('File data is not available for download.');
    }
  };

  const handleDialogClose = () => {
    setSelectedFileData(null); // Close the dialog
  };

  return (
    <div className="sop-versions-overlay">
      <div className="sop-versions-popup">
        <button className="close-button" onClick={handleClose}>
          Close
        </button>
        <h2>Versions</h2>
        <button className="upload-button" onClick={handleUploadClick}>
          Upload New Version
        </button>

        {showUploadDialog && (
          <div className="upload-dialog">
            <h3>Upload New Version</h3>
            <input type="file" onChange={handleFileChange} />
            <div className="dialog-buttons">
              <button onClick={handleCancelClick}>Cancel</button>
              <button onClick={handleUpload}>Upload</button>
            </div>
          </div>
        )}

        <div className="versions-list">
          {versions.map((version, index) => (
            <div key={version.uid} className={`version-item ${index === 0 ? 'latest-version' : ''}`}>
              <span>{formatTimestamp(version.date)}</span>
              <span>{version.filename}</span>
              <div className="version-actions">
                <button onClick={() => handleVersionClick(version.filedata)}>View</button>
                <button onClick={() => handleDownloadClick(version.filedata, version.filename)}>
                  Download
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* File content dialog */}
      {selectedFileData && (
        <div className="file-content-dialog">
          <div className="file-content-box">
            <button className="close-button" onClick={handleDialogClose}>
              Close
            </button>
            <h3>File Content</h3>
            <div className="file-content-scroll">
              <pre>{selectedFileData}</pre>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
