import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from './AuthContext'; // Updated import
import { useNavigate } from 'react-router-dom'; // Updated for useNavigate
import './Organisation.css'; // Import your CSS file
import axios from 'axios';

const Organisation = () => {
  const { organisationId } = useContext(AuthContext); // Retrieve organisation_id from context
  const navigate = useNavigate(); // Hook for navigation
  const [organisationDetails, setOrganisationDetails] = useState({
    organisation_name: '',
    email: '',
    ringfence: 25,
    graceperiod: 15,
    bh_policy: 'Full day BH pay',
    holstart: '01/01/1900', // Updated default value
    startdate: '01/01/1900', // Updated default value
    weekday_ot: 1,
    saturday_ot: 1,
    sunday_ot: 1,
    holallowance: 6,
    bh_ot: 1,
    paycycle: 'Monthly'
    
  });
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const fetchOrganisationDetails = async () => {
      try {
        const response = await axios.get(`https://heuristic-cray.194-76-27-167.plesk.page/api/organisations?org=${organisationId}`);
        
        // No need for response.json() since axios handles it
        const data = response.data; 
        const formattedHolstart = data.holstart ? formatDateToInput(data.holstart) : '1900-01-01';
        const formattedStartDate = data.startdate ? formatDateToInput(data.startdate) : '1900-01-01';
        
        // Check if the response status is 200 (OK)
        if (response.status === 200) {
          setOrganisationDetails(prevDetails => ({
            ...prevDetails,
            ...data,
            holstart: formattedHolstart, // Use the formatted date
            startdate: formattedStartDate,
          }));
        }
      } catch (error) {
        console.error("Error fetching organisation details:", error);
        // Handle error (e.g., set an error state or show a message)
      }
    };

    fetchOrganisationDetails(); // Call the async function
  }, [organisationId]);

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrganisationDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
    setIsDirty(true); // Mark the form as dirty when any field changes
  };

  // Helper function to convert DD/MM/YYYY to YYYY-MM-DD
// Helper function to convert ISO date string to YYYY-MM-DD
const formatDateToInput = (isoDate) => {
  const date = new Date(isoDate); // Create a Date object from the ISO date string
  const year = date.getFullYear(); // Get the year
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed, hence +1) and pad to 2 digits
  const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad to 2 digits
  return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
};



const handleSave = async () => {
  const payload = {
    _id: organisationId, // Assuming you have the organisationId available
    organisation_name: organisationDetails.organisation_name,
    email: organisationDetails.email,
    ringfence: organisationDetails.ringfence,
    graceperiod: organisationDetails.graceperiod,
    bh_policy: organisationDetails.bh_policy,
    holstart: organisationDetails.holstart,
    weekday_ot: organisationDetails.weekday_ot,
    saturday_ot: organisationDetails.saturday_ot,
    sunday_ot: organisationDetails.sunday_ot,
    bh_ot: organisationDetails.bh_ot,
    holallowance: organisationDetails.holallowance,
    paycycle: organisationDetails.paycycle,
    startdate: organisationDetails.startdate 
  };

  try {
    await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/organisation_update_byId', payload);
    console.log('Changes saved successfully:', payload);
    setIsDirty(false); // Reset dirty flag after successful save
    alert('Changes saved successfully!');
  } catch (error) {
    console.error('Error saving changes:', error);
    alert('Failed to save changes.');
  }
};

  const handleCancel = () => {
    navigate('/'); // Redirect to the home page or another desired route
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = ''; // Show warning message on unload
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  return (
    <div className="organisation-container">
      {/* Buttons Above Panels */}
      <div className="form-buttons">
        <button onClick={handleSave}>Save</button>
        <button onClick={handleCancel}>Cancel</button>
      </div>

      {/* Panels Container */}
      <div className="panels-container">
        {/* Left Panel */}
        <div className="panel">
          <h3>Organisation Details</h3>
          <label>
            Organisation Name:
            <input
              type="text"
              name="organisation_name"
              value={organisationDetails.organisation_name}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Organisation Email:
            <input
              type="email"
              name="email"
              value={organisationDetails.email}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Geolocation Ring Fence (metres):
            <input
              type="number"
              name="ringfence"
              value={organisationDetails.ringfence}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Grace Period (minutes):
            <input
              type="number"
              name="graceperiod"
              value={organisationDetails.graceperiod}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Holiday allowance (weeks):
            <input
              type="number"
              name="holallowance"
              value={organisationDetails.holallowance}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Bank Holiday Policy:
            <select name="bh_policy" value={organisationDetails.bh_policy} onChange={handleInputChange}>
              <option value="Full day BH pay">Full day BH pay</option>
              <option value="Pro-rota BH pay">Pro-rota BH pay</option>
              <option value="No BH pay">No BH pay</option>
            </select>
          </label>
          <label>
            Start of Holiday Cycle (dd/mm/1900):
            <input
              type="date"
              name="holstart"
              value={organisationDetails.holstart}
              onChange={handleInputChange}
             />
          </label>
        </div>
        
        {/* Right Panel */}
        <div className="panel">
          <h3>Overtime Rates</h3>
          <label>
            Weekday:
            <input
              type="number"
              name="weekday_ot"
              value={organisationDetails.weekday_ot}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Saturday:
            <input
              type="number"
              name="saturday_ot"
              value={organisationDetails.saturday_ot}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Sunday:
            <input
              type="number"
              name="sunday_ot"
              value={organisationDetails.sunday_ot}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Bank Holiday:
            <input
              type="number"
              name="bh_ot"
              value={organisationDetails.bh_ot}
              onChange={handleInputChange}
            />
          </label>

          <h3>Paycycle Details</h3>
          <div className="paycycle-options">
            <label>
              <input
                type="radio"
                name="paycycle"
                value="Monthly"
                checked={organisationDetails.paycycle === 'Monthly'}
                onChange={handleInputChange}
              />
              Monthly
            </label>
            <label>
              <input
                type="radio"
                name="paycycle"
                value="Fortnightly"
                checked={organisationDetails.paycycle === 'Fortnightly'}
                onChange={handleInputChange}
              />
              Fortnightly
              </label>
            <label>
              <input
                type="radio"
                name="paycycle"
                value="Weekly"
                checked={organisationDetails.paycycle === 'Weekly'}
                onChange={handleInputChange}
              />
              Weekly
            </label>
          </div>
          {organisationDetails.paycycle === 'Fortnightly' && (
            <>
              <label>
                Paycycle start day:
                <input
              type="date"
              name="startdate"
              value={organisationDetails.startdate}
              onChange={handleInputChange}
                />
              </label>
             </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Organisation;
