import React, {useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import duration from 'dayjs/plugin/duration'; 
import holidayAllowanceCalculator from './holidayAllowanceCalculator';
import advancedFormat from 'dayjs/plugin/advancedFormat'; 
import { AuthContext } from './AuthContext';
import Popup from './Popup'; // Import the Popup component
import { Line } from 'react-chartjs-2';
import { FaEnvelope } from 'react-icons/fa'; // Import the envelope icon
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
} from 'chart.js';
import './Staff.css';

dayjs.extend(duration); 
dayjs.extend(advancedFormat); 

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

dayjs.extend(duration); 
dayjs.extend(advancedFormat); 

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);



// Sample staffLogEvents array
const staffLogEvents = [
  { date: '2024-09-15', login_variance: 2 },
  { date: '2024-09-16', login_variance: 5 },
  // ... more log events
];

// Define the grace period
const graceperiod = 3; // Example grace period





// Function to aggregate data by month/year
function aggregateData(logEvents, graceperiod) {
  const result = {};

  logEvents.forEach(event => {
    // Extract year and month from the date
    const date = new Date(event.date);
    const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;

    // Initialize counters if not already present
    if (!result[yearMonth]) {
      result[yearMonth] = {
        withinGracePeriod: 0,
        beyondGracePeriod: 0
      };
    }

    // Count based on login_variance
    if (event.login_variance <= graceperiod) {
      result[yearMonth].withinGracePeriod += 1;
    } else {
      result[yearMonth].beyondGracePeriod += 1;
    }
  });

  return result;
}

// Aggregate data
const aggregatedData = aggregateData(staffLogEvents, graceperiod);


// Function to convert aggregated data to series data for the graph
function prepareGraphData(aggregatedData) {
  const dates = [];
  const withinGracePeriodCounts = [];
  const beyondGracePeriodCounts = [];

  for (const [date, counts] of Object.entries(aggregatedData)) {
    dates.push(date);
    withinGracePeriodCounts.push(counts.withinGracePeriod);
    beyondGracePeriodCounts.push(counts.beyondGracePeriod);
  }

  return {
    dates,
    withinGracePeriod: withinGracePeriodCounts,
    beyondGracePeriod: beyondGracePeriodCounts
  };
}



// Prepare data for the graph
const graphData = prepareGraphData(aggregatedData);



const Staff = () => {
  const [temporaryMessage, setTemporaryMessage] = useState('');
const [showTemporaryPopup, setShowTemporaryPopup] = useState(false);
  const { organisationId } = useContext(AuthContext); // Access organisationId
  const [emailStatus, setEmailStatus] = useState('');
  const [staffMembers, setStaffMembers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logEvents, setLogEvents] = useState([]);
  const [selectedLogEvent, setSelectedLogEvent] = useState(null);
  const [staffSickness, setStaffSickness] = useState([]);
  const [organisationDetails, setOrganisationDetails] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState('');

  const [staffHolidays, setStaffHolidays] = useState([]);
 const [holidayAllowance, setHolidayAllowance] = useState(null);
const [showAddStaffPopup, setShowAddStaffPopup] = useState(false);
const [newFirstName, setNewFirstName] = useState("");
const [newLastName, setNewLastName] = useState("");
 const [newStaffRef, setNewStaffRef] = useState("");
const [popupMessage, setPopupMessage] = useState('');
const [showPopup, setShowPopup] = useState(false);



const [totalHoursWorked, setTotalHoursWorked] = useState("");


  const chartRef = useRef(null);

  const [refresh, setRefresh] = useState(false);




  const showPopupMessage = (message) => {
    setTemporaryMessage(message);   // Set the message to be displayed
    setShowTemporaryPopup(true);    // Show the popup
  
    // Hide the popup after 3 seconds
    setTimeout(() => {
      setShowTemporaryPopup(false); // Hide the popup after 3 seconds
    }, 1500);
  };
  
  
  const sendEmail = async (staff) => {
    const message = buildMessage(staff);
   // const message='test';
    if (staff.email==null || staff.email==''){
      return('No valid email address!');
    }
     
    try {
      await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/send_email', {
       to: staff.email,
            subject: 'Your registration details',
      text: message, // Use the message generated by buildMessage
      });
       showPopupMessage('Email has been sent');
      return 'Email sent successfully'; // Return success message
    } catch (error) {
      showPopupMessage('Email has been sent');
      return 'Email sent successfully'; // Return failure message
    }
  };
  
  

  async function copyWeek(selectedStaff) {
    const week1Entries = selectedStaff.dailyEntries.filter(entry => entry.weekNumber === 1);
    const otherWeeks = selectedStaff.dailyEntries.map(entry => {
      if ([2, 3, 4].includes(entry.weekNumber)) {
        const matchingEntry = week1Entries.find(w1Entry => w1Entry.dayOfWeek === entry.dayOfWeek);
        if (matchingEntry) {
          return {
            ...entry,
            startTime: matchingEntry.startTime,
            endTime: matchingEntry.endTime,
            breakLength: matchingEntry.breakLength,
            hoursWorked: matchingEntry.hoursWorked
          };
        }
      }
      return entry;
    });
  
    selectedStaff.dailyEntries = otherWeeks;
    console.log("Week 1 entries copied to weeks 2, 3, and 4");
    await saveStaffData();
    // Trigger re-render by toggling the `refresh` state
   setSelectedStaff(selectedStaff);
    // setRefresh(prev => !prev);
  }
  
  
//  const handleSendEmail = async (staff) => {
  //  saveStaffData();
    //const message = await sendEmail(staff);
  //  setPopupMessage(message); // Set the message for the popup
  //  setShowPopup(true); // Show the popup
 // };

  const handleClosePopup = () => {
    setShowPopup(false); // Hide the popup
    setPopupMessage(''); // Clear the message
  };

  

  const handleEmailClick = async (staff) => {
    if (!selectedStaff){
      showPopupMessage("Please view the staff's record before sending an email");
      return;
    }
    if (unsavedChanges){
    await saveStaffData;
    }
    
    setSelectedEmail(staff);
    setIsPopupOpen(true);

  };

const handleConfirm = async () => {
  if (selectedStaff) {
      // Use selectedStaff to access the email and any other properties needed
//    await saveStaffData;
    const message= await  sendEmail(selectedStaff);
    setIsPopupOpen(false);
    //     setPopupMessage(message); // Set the message for the popup
   // setShowPopup(true); // Show the popup
  }
  //setIsPopupOpen(false); // Close the dialog
};

 

const handleCancel = () => {
    setIsPopupOpen(false);
};

const EmailDialog = ({ isOpen, onConfirm, onCancel, staff }) => (
  isOpen ? (
      <div className="dialog">
          <p>Are you sure you want to send an email to {staff.firstName} {staff.lastName}?</p>
          <button onClick={onConfirm}>Confirm</button>
          <button onClick={onCancel}>Cancel</button>
      </div>
  ) : null
);


  const fetchOrganisationDetails = async (orgId) => {
    try {
      const response = await axios.get(`https://heuristic-cray.194-76-27-167.plesk.page/api/organisations?org=${orgId}`);
      
      // No need for response.json() since axios handles it
      const data = response.data; 
      const formattedHolstart = data.holstart ? formatDateToInput(data.holstart) : '1900-01-01';

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        setOrganisationDetails(prevDetails => ({
          ...prevDetails,
          ...data,
          holstart: formattedHolstart, // Use the formatted date
        }));
      }
    } catch (error) {
      console.error("Error fetching organisation details:", error);
      // Handle error (e.g., set an error state or show a message)
    }
  };


  const formatDateToInput = (isoDate) => {
    const date = new Date(isoDate); // Create a Date object from the ISO date string
    const year = date.getFullYear(); // Get the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed, hence +1) and pad to 2 digits
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad to 2 digits
    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
  };



  

// Make sure the function is declared as async
const buildMessage = (staff) => {

  let pc="";
  if (organisationDetails.paycycle=="Monthly"){
    pc="Your contracted hours per month: "
  }
  if (organisationDetails.paycycle=="Fortnightly"){
    pc="Your contracted hours per fortnight: "
  }
  

  if (organisationDetails.paycycle=="Weekly"){
    pc="Your contracted hours per week: "
  }
     let message = `This email is to confirm your registration details on the staff portal.\n\n`;
  
    message += `To use the staff portal, download the app and follow the prompts - it will ask you for your email address first and send you an activation code. After that to use the app you only need your PIN number\n\n`;
    
    message += `Your PIN number is ${staff.pin}\n\n`;
    message += `${pc} ${staff.contracted_hours_per_month}\n`;
    message += `Normal place of work is: ${staff.default_location_name}\n`;
    message += `Actual place of work identified by: ${staff.usegeolocate || 'Not specified'}\n`;
    message += `Holiday allowance per year (wks): ${staff.holallowance}\n`;
    message += `Standard hourly pay rate: ${staff.hourlyPayRate}\n`;
    message += `Your role: ${staff.role}\n\n`;
  
    // Only display the weekly working pattern if hourPattern is "Regular hours"
    if (staff.hourPattern === 'Regular hours') {
   
      message += `Your working pattern:\n\n`;
      
   
      message += generateMessageByWeek(staff.dailyEntries);
   
    } else {
      message += "You do not have a regular working pattern.\n";
    }
  
    return message;
  };
   
  
  
  const generateMessageByWeek = (dailyEntries) => {
    let formattedMessage = '';
  
    // Group entries by weekNumber
    const weeks = dailyEntries.reduce((acc, entry) => {
      if (!acc[entry.weekNumber]) {
        acc[entry.weekNumber] = [];
      }
      acc[entry.weekNumber].push(entry);
      return acc;
    }, {});
  
    // Determine which weeks to display based on paycycle
    const filteredWeeks = Object.keys(weeks).filter(week => {
      const weekNumber = parseInt(week, 10); // Convert to number for comparison
      if (organisationDetails.paycycle === 'Monthly') {
        return weekNumber >= 1 && weekNumber <= 4; // Show all 4 weeks
      }
      if (organisationDetails.paycycle === 'Fortnightly') {
        return weekNumber === 1 || weekNumber === 2; // Show weeks 1 and 2
      }
      if (organisationDetails.paycycle === 'Weekly') {
        return weekNumber === 1; // Show only week 1
      }
      return false; // Default: exclude other weeks
    });
  
    // Define fixed width for each column (for padding purposes)
    const dayOfWeekWidth = 5; // E.g., 'Mon', 'Tue', 'Wed' will fit in 5 characters
    const timeWidth = 8; // Time like '09:00' should fit in 8 characters
    const breakLengthWidth = 12; // 'Break: 1 hour' should be padded to 15 characters
  
    // Iterate through each week and create the message
    filteredWeeks.forEach(week => {
      formattedMessage += `Week ${week}\n`; // Add week title
  
      // Format the entries for each day in the week
      weeks[week].forEach(entry => {
        // Skip entries with 00:00 startTime or endTime
        if (entry.startTime === '00:00' || entry.endTime === '00:00') {
          return; // Skip this entry if start or end time is 00:00
        }
  
        // Pad the columns for consistent formatting
        const paddedDay = entry.dayOfWeek.padEnd(dayOfWeekWidth); // Padding for day of the week
        const paddedStartTime = entry.startTime.padStart(timeWidth); // Padding for start time
        const paddedEndTime = entry.endTime.padStart(timeWidth); // Padding for end time
        const paddedBreakLength = `Break: ${entry.breakLength} hr`.padEnd(breakLengthWidth); // Padding for break length
  
        // Concatenate the formatted data
        formattedMessage += ` ${paddedDay}  ${paddedStartTime} - ${paddedEndTime}      ${paddedBreakLength}\n`;
      });
  
      formattedMessage += '\n'; // Add an empty line between weeks
    });
  
    return formattedMessage;
  };
    
 
 
  const loadData = async () => {
    try {
      // Fetch locations
      const locationResponse = await axios.get(
        `https://heuristic-cray.194-76-27-167.plesk.page/api/locations?org=${organisationId}`
      );
      setLocations(locationResponse.data || []); // Ensure the data is an array
  
      // Fetch roles
      const rolesResponse = await axios.get(
        'https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles'
      );
      setRoles(rolesResponse.data);
  
      // Fetch staff based on location or organization
      const staffUrl = selectedLocation
        ? `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_filtered?default_location_id=${encodeURIComponent(
            selectedLocation
          )}`
        : `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?organisation_id=${organisationId}`;
      const staffResponse = await axios.get(staffUrl);
  
      // Filter out admin staff and format `paycycleStart`
      const filteredStaffMembers = (staffResponse.data || [])
        .filter(
          staff => !(staff.firstName === "Admin" && staff.lastName === "Admin")
        )
        .map(staff => ({
          ...staff,
          paycycleStart: staff.paycycleStart
            ? formatDateForInput(staff.paycycleStart) // Use utility function
            : formatDateForInput(new Date()) // Default to today's date
        }));
  
      setStaffMembers(filteredStaffMembers);
  
      // If a specific staff member is selected, fetch related data
      if (selectedStaff && selectedStaff._id) {
        await fetchHolidayAllowance();
  
        const logEventsUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events?staff_id=${selectedStaff._id}`;
        const logEventsResponse = await axios.get(logEventsUrl);
        setLogEvents(
          logEventsResponse.data.sort((a, b) => new Date(b.created) - new Date(a.created))
        );
  
        const sicknessUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sickness?staff_id=${selectedStaff._id}`;
        const sicknessResponse = await axios.get(sicknessUrl);
        setStaffSickness(sicknessResponse.data);
  
        const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday?staff_id=${selectedStaff._id}`;
        const holidaysResponse = await axios.get(holidaysUrl);
        setStaffHolidays(holidaysResponse.data);
      }
    } catch (error) {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };
  
 
 
  useEffect(() => {
     
    loadData();
    fetchOrganisationDetails(organisationId);
    // Calculate totalHoursWorked only if selectedStaff is available
    if (selectedStaff) {
        const totalHoursWorked = selectedStaff.dailyEntries.reduce((sum, entry) =>
            sum + (entry.hoursworked > 0 ? entry.hoursworked : 0), 0);

        setTotalHoursWorked(totalHoursWorked); // Set the total hours worked in state
    }
}, [selectedLocation, selectedStaff]); // Depend on both selectedLocation and selectedStaff



  
  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const formatDateForInput = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Format for <input type="date">
  };
  
  const handleStaffClick = async (staff) => {
    try {
      if (unsavedChanges) {
        const saveResponse = await saveStaffData(); // Save current staff data
        if (!saveResponse.success) {
          console.error('Failed to save data before switching staff');
          return; // Exit if save fails
        }
      }
  
      // Fetch updated staff data after saving
      const response = await axios.get(`https://heuristic-cray.194-76-27-167.plesk.page/api/staff_filtered?staff_id=${staff._id}`);
      const updatedStaff = response.data[0];
  
      const formattedStaff = {
        ...updatedStaff,
        paycycleStart: updatedStaff.paycycleStart
          ? formatDateForInput(updatedStaff.paycycleStart)
          : formatDateForInput(new Date()) // Default to today's date
      };
  
      setSelectedStaff(formattedStaff); // Update with fetched and formatted data
      setActiveTab(1); // Set default tab or any other logic needed
    } catch (error) {
      console.error('Error handling staff click:', error);
    }
  };
  

  const handleOpenAddStaffPopup = () => {
    setNewFirstName('');
    setNewLastName('');
    setNewStaffRef(uuidv4());
      
    setShowAddStaffPopup(true);
  };

  const handleCloseAddStaffPopup = () => {
    setShowAddStaffPopup(false);
  };

  const handleSaveNewStaff = async () => {
    const newStaffMember = {
      firstName: newFirstName,  // Use newFirstName for the first name field
      lastName: newLastName,    // Use newLastName for the last name field
      staff_ref: newStaffRef,
      weekday_ot: organisationDetails.weekday_ot,
      saturday_ot: organisationDetails.saturday_ot,
      sunday_ot: organisationDetails.sunday_ot,
      bh_ot: organisationDetails.bh_ot,
      paycycleStart: new Date().toISOString(),
      created: new Date().toISOString(),
      active: true,
      organisation_name: organisationDetails.name, // Added field
      default_location_id:'',
      default_location_name: '',
       role: '',
      pin:  Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000,
      access_rights: 'User',
      mobile: '',
      hourPattern: 'Regular hours',
      userGuid: '',
      email: '',
      usegeolocate: 'RFID & geolocation',
      hourlyPayRate: 0,
      holallowance: organisationDetails.holallowance,
      holadjustmnt: 0,
      contracted_hours_per_month: 140,
      deviceId: '',
     "dailyEntries": [
    {
      "dayOfWeek": "Mon",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 1 
    },
    {
      "dayOfWeek": "Tue",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 1 
    },
    {
      "dayOfWeek": "Wed",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 1 
    },
    {
      "dayOfWeek": "Thu",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 1 
    },
    {
      "dayOfWeek": "Fri",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 1 
    },
    {
      "dayOfWeek": "Sat",
      "startTime": "09:00",
      "endTime": "13:00",
      "breakLength": 0,
      "hoursworked": 4,
      "weekNumber": 1 
    },
    {
      "dayOfWeek": "Sun",
      "startTime": "00:00",
      "endTime": "00:00",
      "breakLength": 0,
      "hoursworked": 0,
      "weekNumber": 1 
    },
    {
      "dayOfWeek": "Mon",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 2 
    },
    {
      "dayOfWeek": "Tue",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 2 
    },
    {
      "dayOfWeek": "Wed",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 2 
    },
    {
      "dayOfWeek": "Thu",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 2 
    },
    {
      "dayOfWeek": "Fri",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 2 
    },
    {
      "dayOfWeek": "Sat",
      "startTime": "09:00",
      "endTime": "13:00",
      "breakLength": 0,
      "hoursworked": 4,
      "weekNumber": 2 
    },
    {
      "dayOfWeek": "Sun",
      "startTime": "00:00",
      "endTime": "00:00",
      "breakLength": 0,
      "hoursworked": 0,
      "weekNumber": 2 
    },
    {
      "dayOfWeek": "Mon",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 3 
    },
    {
      "dayOfWeek": "Tue",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 3 
    },
    {
      "dayOfWeek": "Wed",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 3 
    },
    {
      "dayOfWeek": "Thu",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 3 
    },
    {
      "dayOfWeek": "Fri",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 3 
    },
    {
      "dayOfWeek": "Sat",
      "startTime": "09:00",
      "endTime": "13:00",
      "breakLength": 0,
      "hoursworked": 4,
      "weekNumber": 3 
    },
    {
      "dayOfWeek": "Sun",
      "startTime": "00:00",
      "endTime": "00:00",
      "breakLength": 0,
      "hoursworked": 0,
      "weekNumber": 3 
    },
    {
      "dayOfWeek": "Mon",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 4 
    },
    {
      "dayOfWeek": "Tue",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 4 
    },
    {
      "dayOfWeek": "Wed",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 4 
    },
    {
      "dayOfWeek": "Thu",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 4 
    },
    {
      "dayOfWeek": "Fri",
      "startTime": "09:00",
      "endTime": "17:00",
      "breakLength": 1,
      "hoursworked": 7,
      "weekNumber": 4 
    },
    {
      "dayOfWeek": "Sat",
      "startTime": "09:00",
      "endTime": "13:00",
      "breakLength": 0,
      "hoursworked": 4,
      "weekNumber": 4 
    },
    {
      "dayOfWeek": "Sun",
      "startTime": "00:00",
      "endTime": "00:00",
      "breakLength": 0,
      "hoursworked": 0,
      "weekNumber": 4 
    }
  ],
      organisation_id: organisationId,
        // Adjust to match your context
    };
  
    try {
      await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_register', newStaffMember);
      console.log('New staff member added successfully');
       setShowAddStaffPopup(false); // Close the popup after saving
      loadData();
      // Optionally, refresh staff members list here if needed
    } catch (error) {
      console.error('Error adding new staff member:', error);
    }
  };
  


  const fetchHolidayAllowance = async () => {
    if (selectedStaff) {
      try {
        const response = await holidayAllowanceCalculator(selectedStaff._id); // Assuming _id is used as itemid
        setHolidayAllowance(response);
      } catch (error) {
        console.error("Error fetching holiday allowance:", error);
      }
    }
  };




  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Check if this is the "copy week" trigger
    if (name === 'copyWeekTrigger') {
        // Display confirmation dialog
        const userConfirmed = window.confirm("This will copy your Week 1 template to all other weeks. Continue?");
        if (userConfirmed) {
            setSelectedStaff(prevStaff => ({
                ...prevStaff,
                dailyEntries: copyWeek(prevStaff)
            }));
            setUnsavedChanges(true); // Track unsaved changes
        }
        return; // Exit after handling "copy week"
    }

    // Handle other input changes
    setUnsavedChanges(true);
    setSelectedStaff(prevStaff => ({
        ...prevStaff,
        [name]: type === 'checkbox' ? checked : value,
    }));
};

  const handleLocationSelectionChange = (e, locationName) => {
    const { value } = e.target; // Get the selected value (_id)
    setUnsavedChanges(true); // Mark as unsaved changes

    setSelectedStaff((prevState) => ({
        ...prevState,
        default_location_id: value, // Update default_location_id
        default_location_name: locationName, // Update default_location_name
    }));
};



const saveStaffData = async () => {
  try {
      await axios.post(`https://heuristic-cray.194-76-27-167.plesk.page/api/staff_update`, selectedStaff);
      setUnsavedChanges(false);

      // Show success dialog for a few seconds
      showPopupMessage('Record updated successfully');
 
      return { success: true }; // Explicit success response
  } catch (error) {
        showPopupMessage('Error updating record');
 
      return { success: false }; // Explicit failure response
  }
};

 
const calculateHoursWorked = (startTime, endTime, breakLength) => {
  if (!startTime || !endTime || isNaN(breakLength)) {
      return 'Invalid input';
  }

  // Split the input time strings into hours and minutes
  const [startHours, startMinutes] = startTime.trim().split(':').map(Number);
  const [endHours, endMinutes] = endTime.trim().split(':').map(Number);

  // Create Date objects for the start and end times
  const start = new Date();
  const end = new Date();

  start.setHours(startHours, startMinutes, 0, 0);
  end.setHours(endHours, endMinutes, 0, 0);

  // Adjust end time if it is before start time (next day)
  if (end < start) {
      end.setDate(end.getDate() + 1);
  }

  // Calculate the total minutes worked
  const totalMinutes = (end - start) / (1000 * 60); // Convert milliseconds to minutes

  // Parse the break length
  const breakMinutes = parseFloat(breakLength) * 60;

  if (isNaN(breakMinutes) || breakMinutes < 0) {
      return 'Invalid break length';
  }

  // Calculate hours worked
  const hoursWorked = (totalMinutes - breakMinutes) / 60;

  // Calculate the total hours worked across all entries
  //return Math.max(hoursWorked, 0).toFixed(1); // Ensure we don't return negative hours
  return Math.max(hoursWorked, 0).toFixed(1) * 1; // Converts string to a number

};

 
 

  const graphData = {
    labels: logEvents.map(event => event._id),
    datasets: [
      {
        label: 'Login Variance',
        data: logEvents.map(event => ({
          x: event._id,
          y: event.login_variance,
        })),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const event = logEvents.find(e => e._id === tooltipItem.raw.x);
            return event ? `Login Variance: ${event.login_variance}` : 'N/A';
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Log Event ID'
        },
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false, // Prevents auto skipping of x-axis labels
          maxRotation: 90, // Rotate labels if they are too long
          minRotation: 45, // Minimum rotation angle
        },
      },
      y: {
        min: -50,
        max: 50,
        ticks: {
          callback: (value) => value.toFixed(2),
        },
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        border: {
          display: true,
        },
      },
    },
  };

  
  
  
  const handlePointClick = (event) => {
    const chart = chartRef.current?.chart;
    if (chart) {
      const elements = chart.getElementsAtEventForMode(event.native, 'nearest', { intersect: true }, false);
      if (elements.length) {
        const element = elements[0];
        const logEvent = logEvents.find(e => e._id === element.raw.x);
        setSelectedLogEvent(logEvent);
      }
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="container">
 {/* Left Section: Staff List */}
<div className="left-section">
  <button 
    style={{ marginBottom: '10px', padding: '8px 16px' }} 
    onClick={saveStaffData}
  >
   
   Save Data
        </button>
        <button 
          style={{ marginBottom: '10px', padding: '8px 16px' }} 
          onClick={handleOpenAddStaffPopup}
        >
          
          Add New Staff Member
        </button>

  <h3>Staff List</h3>
  <select className="location-select" value={selectedLocation} onChange={handleLocationChange}>
    <option value=''>All Locations</option>
    {locations.map((location) => (
      <option key={location._id} value={location._id}>
        {location.name}
      </option>
    ))}
  </select>
  <div className="staff-list">
    {staffMembers.map((staff, index) => (
      <div
        key={staff._id}
      //  className={`staff-item ${index % 2 === 0 ? 'even' : 'odd'}`}
      className={`staff-item ${index % 2 === 0 ? 'even' : 'odd'} ${selectedStaff && selectedStaff._id === staff._id ? 'selected' : ''}`}
 
      onClick={() => handleStaffClick(staff)}
      >
        <span>
          {staff.lastName}, {staff.firstName} - {staff.default_location_name}
        </span>
           {/* Add the envelope icon */}
           <FaEnvelope onClick={() => handleEmailClick(selectedStaff)} /> {/* Trigger email popup */}
        
        <EmailDialog
            isOpen={isPopupOpen}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            staff={selectedStaff}  // Pass the selected staff to the dialog
        />
   {showPopup && <Popup message={popupMessage} onClose={handleClosePopup} />}
            
        
      </div>
      
    ))}
  </div>
</div>


      {/* Add Staff Popup */}
      {showAddStaffPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Add New Staff Member</h3>
            <label>
            First Name:
        <input
          type="text"
          value={newFirstName}
          onChange={(e) => setNewFirstName(e.target.value)}
        />
      </label>
      <br />
      <label>
        Last Name:
        <input
          type="text"
          value={newLastName}
          onChange={(e) => setNewLastName(e.target.value)}
        />
      </label>
      <br />
      <label>
            Staff Reference (GUID):
<input
  type="text"
  value={newStaffRef}
  onChange={(e) => setNewStaffRef(e.target.value)}
  style={{ width: "300px" }}  // Adjust width as needed
/>

            </label>
            <button onClick={handleSaveNewStaff}>Save</button>
            <button onClick={handleCloseAddStaffPopup}>Cancel</button>
          </div>
        </div>
      )}


      {/* Middle Section: Edit Form */}
      <div className="middle-section">
        <h3>Edit Staff Member</h3>
        {selectedStaff ? (
          <div>
            {/* Dynamic Form Generation */}
            {Object.keys(selectedStaff).map((key) => (
            key !== 'created' &&
            key !== 'organisation_id' &&
            key !== 'userGuid' &&
            key !== 'deviceId' &&
            key !== 'mobile' &&
            key !== 'paycycleStart' &&
            key !== 'staff_ref' &&
            key !== 'usegeolocate' &&
            key !== 'contracted_hours_per_month' &&
            key !== 'hourlyPayRate' &&
            key !== 'organisation_name' &&
            key !== 'saturday_ot' &&
            key !== 'sunday_ot' &&
            key !== 'bh_ot' &&
            key !== 'sunday_ot' &&
            key !== 'appVersion' &&
            key !== 'lastLogon' &&
            key !== 'holallowance' &&
            key !== 'holadjustmnt' &&
            key !== 'role' &&
  //          key !== 'pin' &&
            key !== 'default_location_name' &&
    //        key !== 'default_location_id' &&
            key !== 'dailyEntries' &&
            key !== '_id' &&
            key !== '__v' && (
              <div key={key} className="form-group">
                {key === 'hourPattern' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label style={{ marginRight: '10px' }}>Hour Pattern:</label>
                      <select
                        name="hourPattern"
                        value={selectedStaff.hourPattern || ''}
                        onChange={handleInputChange}
                        className="form-input"
                        style={{ marginRight: '20px' }}
                      >
                        <option value="">Select Hour Pattern</option>
                        <option value="Regular hours">Regular hours</option>
                        <option value="Variable hours">Variable hours</option>
                        <option value="Salary based">Salary based</option>
                      </select>
                      
                      <label style={{ marginRight: '10px' }}>Role:</label>
                      <select
                        name="role"
                        value={selectedStaff.role || ''}
                        onChange={handleInputChange}
                        className="form-input"
                      >
                        <option value="">Select Role</option>
                        {roles.map((role) => (
                          <option key={role._id} value={role.role}>
                            {role.role}
                          </option>
                        ))}
                      </select>
                    </div>
                  
                  
): key === 'pin' ? (
<div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
  
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
  <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      <label style={{ marginRight: '10px' }}>PIN:</label>
      <input
        type="number"
        name="pin"
        value={selectedStaff.pin || ''}
        onChange={handleInputChange}
        className="form-input"
        placeholder="PIN"
      />
    </div>

    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      <label style={{ marginRight: '10px' }}>Hol allowance (wks):</label>
      <input
        type="number"
        name="holallowance"
        value={selectedStaff.holallowance || ''}
        onChange={handleInputChange}
        className="form-input"
        placeholder="Hol. allow"
      />
    </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label style={{ marginRight: '10px' }}>Hol adjustment (hrs):</label>
      <input
        type="number"
        name="holadjustmnt"
        value={selectedStaff.holadjustmnt || ''}
        onChange={handleInputChange}
        className="form-input"
        placeholder="Hol. adjust"
      />
    </div>
  </div>

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      <label style={{ marginRight: '10px' }}>Staff ref:</label>
      <input
        type="text"
        name="staff_ref"
        value={selectedStaff.staff_ref || ''}
        onChange={handleInputChange}
        className="form-input"
        placeholder="Enter staff reference"
      />
    </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
  <label style={{ marginRight: '10px' }}>Geolocation:</label>
  <select
    name="usegeolocate"
    value={selectedStaff.usegeolocate || ''}
    onChange={handleInputChange}
    className="form-input"
    style={{ width: '165px' }} // Adjust the width as needed
  >
    <option value="RFID & geolocation">RFID & geolocation</option>
    <option value="RFID only">RFID only</option>
    <option value="Digital nomad">Digital nomad</option>
  </select>
</div>


  </div>

</div>

  
                
): key === 'email' ? (
<div className="form-group">
  
  {/* First Row: Email and Mobile */}
  <div style={{ width: '600px', display: 'flex', flexDirection: 'row', alignItems: 'right', marginBottom: '10px' }}>
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      <label style={{ marginRight: '10px' }}>Email:</label>
      <input
        type="text"
        name="email"
        value={selectedStaff.email || ''}
        onChange={handleInputChange}
        className="form-input"
        placeholder="Email"
      />
    </div>

    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
      <label style={{ marginRight: '10px' }}>Mobile:</label>
      <input
        type="text"
        name="mobile"
        value={selectedStaff.mobile || ''}
        onChange={handleInputChange}
        className="form-input"
        placeholder="Enter mobile"
      />
    </div>
  </div>

  {/* Second Row: Hourly pay rate and Contracted hours */}
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      <label style={{ marginRight: '10px' }}>Hourly pay rate:</label>
      <input
        type="number"
        name="hourlyPayRate"
        value={selectedStaff.hourlyPayRate || ''}
        onChange={handleInputChange}
        className="form-input"
        placeholder="Hourly pay rate"
      />
    </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label style={{ marginRight: '10px' }}>Paycycle start:</label>
    <input
  type="date"
  name="paycycleStart"
  value={selectedStaff.paycycleStart || ''} // Ensure `paycycleStart` is in YYYY-MM-DD format
  onChange={handleInputChange}
  className="form-input"
/>
</div>




    <div style={{ display: 'flex', alignItems: 'center' }}>
      <label style={{ marginRight: '10px' }}>Cont. hrs/mth:</label>
      <input
        type="number"
        name="contracted_hours_per_month"
        value={selectedStaff.contracted_hours_per_month || ''}
        onChange={handleInputChange}
        className="form-input"
        placeholder="Cont. hrs/mth"
      />
    </div>
  </div>

</div>
 
): key === 'default_location_id' ? (
  <>
    {/* Default Location and Access Rights */}
    <div
      className="form-group"
      style={{
        display: 'flex',
        flexDirection: 'column', // Changed from 'row' to 'column' for better stacking
        gap: '20px', // Added gap for better spacing
        alignItems: 'flex-start',
        marginBottom: '20px'
      }}
    >
      {/* Default Location */}
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <label style={{ marginRight: '10px' }}>Default:</label>
        <select
          name="default_location_id"
          value={selectedStaff?.default_location_id || ''}
          onChange={(event) => {
            const selectedLocation = locations.find(
              (location) => location._id === event.target.value
            );
            handleLocationSelectionChange(
              event,
              selectedLocation ? selectedLocation.name : ''
            );
          }}
          className="form-input"
        >
          <option value="">Select Default location</option>
          {locations.map((location) => (
            <option key={location._id} value={location._id}>
              {location.name}
            </option>
          ))}
        </select>
      </div>

      {/* Access Rights */}
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <label style={{ marginRight: '10px' }}>Rights:</label>
        <select
          name="access_rights"
          value={selectedStaff?.access_rights || ''}
          onChange={handleInputChange}
          className="form-input"
        >
          <option value="">Select Access Rights</option>
          <option value="User">User</option>
          <option value="Manager">Manager</option>
        </select>
      </div>
    </div>
  </>
) : key === 'weekday_ot' ? (
  <>
    {/* Overtime Section */}
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
      <h3>Overtime</h3>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '20px' }}>
        <label style={{ marginRight: '10px' }}>Wkdy:</label>
        <input
          type="number"
          name="weekday_ot"
          value={selectedStaff?.weekday_ot || ''}
          onChange={handleInputChange}
          className="form-input"
          style={{ width: '40px' }}
        />

        <label style={{ marginRight: '10px' }}>Sat:</label>
        <input
          type="number"
          name="saturday_ot"
          value={selectedStaff?.saturday_ot || ''}
          onChange={handleInputChange}
          className="form-input"
          style={{ width: '40px' }}
        />

        <label style={{ marginRight: '10px' }}>Sun:</label>
        <input
          type="number"
          name="sunday_ot"
          value={selectedStaff?.sunday_ot || ''}
          onChange={handleInputChange}
          className="form-input"
          style={{ width: '40px' }}
        />

        <label style={{ marginRight: '10px' }}>B/h:</label>
        <input
          type="number"
          name="bh_ot"
          value={selectedStaff?.bh_ot || ''}
          onChange={handleInputChange}
          className="form-input"
          style={{ width: '40px' }}
        />
      </div>
    </div>
  </>
)  : key === 'active' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label>Active:</label>
                      <input
                        type="checkbox"
                        name="active"
                        checked={selectedStaff.active || false}
                        onChange={handleInputChange}
                        className="form-checkbox"
                        style={{ marginRight: '10px', marginLeft: '10px' }}
                      />
                      <label style={{ marginRight: '10px' }}>First Name:</label>
                      <input
                        type="text"
                        name="firstName"
                        value={selectedStaff.firstName || ''}
                        onChange={handleInputChange}
                        className="form-input small-input"
                      />
                      <label style={{ marginLeft: '20px', marginRight: '10px' }}>Last Name:</label>
                      <input
                        type="text"
                        name="lastName"
                        value={selectedStaff.lastName || ''}
                        onChange={handleInputChange}
                        className="form-input small-input"
                      />
                    </div>
                  )  : (
                    key !== 'firstName' &&
                    key !== 'lastName' && (
                      <div className="form-group">
                        <label>
                          {key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}:
                        </label>
                        <input
                          type="text"
                          name={key}
                          value={selectedStaff[key] || ''}
                          onChange={handleInputChange}
                          className="form-input"
                        />
                      </div>
                    )
                  )}
                </div>
              )
            ))}

{organisationDetails.paycycle !== 'Weekly' && (
  <button
    onClick={() => handleInputChange({ target: { name: 'copyWeekTrigger', value: true } })}
    style={{ marginBottom: '10px' }}
  >
    Copy Week
  </button>
)}


<span style={{ marginLeft: '10px' }}>
            Total Hours Worked: {totalHoursWorked || 0} {/* Fallback to 0 if totalHoursWorked is not defined */}
        </span>



{/* Daily Entries Tabs */}
<div>
  <div className="tabs">
    {[1, 2, 3, 4]
      .filter((week) => {
        if (organisationDetails.paycycle === 'Weekly') {
          return week === 1; // Only show Week 1
        } else if (organisationDetails.paycycle === 'Fortnightly') {
          return week === 1 || week === 2; // Show Week 1 and Week 2
        } else {
          return true; // Show all weeks for Monthly or other cases
        }
      })
      .map((week) => (
        <button
          key={week}
          onClick={() => setActiveTab(week)}
          className={`tab-button ${activeTab === week ? 'active' : ''}`}
        >
          Week {week}
        </button>
      ))}
  </div>

  <div className="tab-content">
    {selectedStaff.dailyEntries.length > 0 && (
      <>
        {/* Labels displayed only once */}
        <div className="entry-fields-labels">
          <span className="label-day">Day</span>
          <span>Start</span>
          <span>End</span>
          <span>Break</span>
          <span>Hrs worked</span>
        </div>

        {/* Loop through daily entries */}
        {selectedStaff.dailyEntries
          .filter((entry) => {
            if (organisationDetails.paycycle === 'Weekly') {
              return entry.weekNumber === 1 && entry.weekNumber === activeTab;
            } else if (organisationDetails.paycycle === 'Fortnightly') {
              return (
                (entry.weekNumber === 1 || entry.weekNumber === 2) &&
                entry.weekNumber === activeTab
              );
            } else {
              return entry.weekNumber === activeTab; // Monthly or other cases
            }
          })
          .map((entry) => (
            <div key={entry.dayOfWeek} className="daily-entry">
              <div className="entry-fields">
                {/* Delete icon next to day of the week */}
                <span
                  className="delete-icon"
                  onClick={() => {
                    const updatedEntries = selectedStaff.dailyEntries.map(
                      (existingEntry) =>
                        existingEntry.weekNumber === activeTab &&
                        existingEntry.dayOfWeek === entry.dayOfWeek
                          ? {
                              ...existingEntry,
                              startTime: '00:00',
                              endTime: '00:00',
                              hoursworked: 0,
                              breakLength: 0,
                            }
                          : existingEntry
                    );
                    setSelectedStaff({
                      ...selectedStaff,
                      dailyEntries: updatedEntries,
                    });
                    setUnsavedChanges(true);
                  }}
                >
                  🗑️
                </span>

                {/* Display the day of the week inline */}
                <span className="day-label">{entry.dayOfWeek}</span>

                {/* Start Time */}
                <div className="entry-field">
                  <input
                    type="time"
                    value={entry.startTime}
                    onChange={(e) => {
                      const updatedEntries = selectedStaff.dailyEntries.map(
                        (existingEntry) =>
                          existingEntry.weekNumber === activeTab &&
                          existingEntry.dayOfWeek === entry.dayOfWeek
                            ? {
                                ...existingEntry,
                                startTime: e.target.value,
                                hoursworked: calculateHoursWorked(
                                  e.target.value,
                                  existingEntry.endTime,
                                  existingEntry.breakLength
                                ),
                              }
                            : existingEntry
                      );

                      setSelectedStaff({
                        ...selectedStaff,
                        dailyEntries: updatedEntries,
                      });

                      const totalHoursWorked = updatedEntries.reduce(
                        (sum, entry) =>
                          sum +
                          (entry.hoursworked > 0
                            ? Number(entry.hoursworked)
                            : 0),
                        0
                      );
                      setTotalHoursWorked(totalHoursWorked);
                      setUnsavedChanges(true);
                    }}
                    className="entry-input"
                  />
                </div>

                {/* End Time */}
                <div className="entry-field">
                  <input
                    type="time"
                    value={entry.endTime}
                    onChange={(e) => {
                      const updatedEntries = selectedStaff.dailyEntries.map(
                        (existingEntry) =>
                          existingEntry.weekNumber === activeTab &&
                          existingEntry.dayOfWeek === entry.dayOfWeek
                            ? {
                                ...existingEntry,
                                endTime: e.target.value,
                                hoursworked: calculateHoursWorked(
                                  existingEntry.startTime,
                                  e.target.value,
                                  existingEntry.breakLength
                                ),
                              }
                            : existingEntry
                      );

                      setSelectedStaff({
                        ...selectedStaff,
                        dailyEntries: updatedEntries,
                      });

                      const totalHoursWorked = updatedEntries.reduce(
                        (sum, entry) =>
                          sum +
                          (entry.hoursworked > 0
                            ? Number(entry.hoursworked)
                            : 0),
                        0
                      );
                      setTotalHoursWorked(totalHoursWorked);
                      setUnsavedChanges(true);
                    }}
                    className="entry-input"
                  />
                </div>

                {/* Break Length */}
                <div className="entry-field">
                  <input
                    type="text"
                    value={entry.breakLength}
                    onChange={(e) => {
                      const updatedEntries = selectedStaff.dailyEntries.map(
                        (existingEntry) =>
                          existingEntry.weekNumber === activeTab &&
                          existingEntry.dayOfWeek === entry.dayOfWeek
                            ? {
                                ...existingEntry,
                                breakLength: e.target.value,
                                hoursworked: calculateHoursWorked(
                                  existingEntry.startTime,
                                  existingEntry.endTime,
                                  e.target.value
                                ),
                              }
                            : existingEntry
                      );

                      setSelectedStaff({
                        ...selectedStaff,
                        dailyEntries: updatedEntries,
                      });

                      const totalHoursWorked = updatedEntries.reduce(
                        (sum, entry) =>
                          sum +
                          (entry.hoursworked > 0
                            ? Number(entry.hoursworked)
                            : 0),
                        0
                      );
                      setTotalHoursWorked(totalHoursWorked);
                      setUnsavedChanges(true);
                    }}
                    className="entry-input"
                  />
                </div>

                {/* Hours Worked */}
                <div className="entry-field">
                  <input
                    type="text"
                    value={entry.hoursworked}
                    disabled
                    className="entry-input"
                  />
                </div>
              </div>
            </div>
          ))}
      </>
    )}
  </div>
</div>



 
                       <button
              onClick={saveStaffData}
              disabled={!unsavedChanges}
              className="save-button"
            >
              Save
            </button>
          </div>
        ) : (
          <p>Select a staff member to edit</p>
        )}
      </div>

{/* Right Section: Report Detail */}
<div className="right-section">
 

  <div className="boxes-container">
    {/* Box 1: Holidays */}
    <div className="box">
      <h4>Holidays</h4>
      <ul>
        {staffHolidays.length > 0 ? (
          staffHolidays.map((holiday, index) => (
            <li key={index}>
              {dayjs(holiday.start).format('DD/MM/YY')} - {dayjs(holiday.end).format('DD/MM/YY')}: {holiday.status}
            </li>
          ))
        ) : (
          <li>No holiday records available.</li>
        )}
      </ul>
    </div>


 {/* New Box: Holiday Allowance */}
<div className="box">
  <h4>Holiday Allowance</h4>
  <ul>
    {holidayAllowance && holidayAllowance.length > 0 ? (
      holidayAllowance.map((entry, index) => (
        <li key={index}>
          {entry.year} - Taken: {entry.hrstaken} - Allowed: {entry.hrsallowed}
        </li>
      ))
    ) : (
      <li>No holiday allowance records available.</li>
    )}
  </ul>
</div>

    {/* Box 2: Sickness Log */}
    <div className="box">
      <h4>Sickness Log</h4>
      <ul>
        {staffSickness.length > 0 ? (
          staffSickness.map((sickness, index) => (
            <li key={index}>
              {dayjs(sickness.created).format('DD/MM/YY')} - {sickness.daysAway === 0 ? '-' : sickness.daysAway} (dys) {sickness.status}
            </li>
          ))
        ) : (
          <li>No sickness records available.</li>
        )}
      </ul>
    </div>
    {/* Box 3: Logevents */}
    <div className="box">
      <h4>Log Events</h4>
      <ul>
        {logEvents.length > 0 ? (
          logEvents.map((logevent, index) => (
            <li key={index}>
           {dayjs(logevent.created).format('DD/MM/YY')} - {logevent.log_status} - 
    {logevent.stdhrsworked}/{logevent.overtimehrs} - 
    <input 
      type="checkbox" 
      checked={logevent.overtimerequested} 
      disabled 
    />     
       </li>
          ))
        ) : (
          <li>No log available.</li>
        )}
      </ul>
    </div>



  </div>

   {/* Popup Overlay (For confirming actions like email send) */}
   {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <p>This will send an email with the PIN and installation instructions to this user for the staff app. Proceed?</p>
            <button onClick={handleConfirm}>Yes</button>
            <button onClick={handleCancel}>No</button>
          </div>
        </div>
      )}

      {/* Temporary Popup (Displays for 3 seconds) */}
      {showTemporaryPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <p>{temporaryMessage}</p>
          </div>
        </div>
      )}

  
 

     
    </div>
    </div>
    
  );
};

export default Staff;