import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
//import CalendarPopup from './CalendarPopup'; // Import the CalendarPopup component
import './timesheets.css';
import ReviewLogEvent from './reviewLogEvent.js';
import * as XLSX from 'xlsx';
import { AuthContext } from './AuthContext';

const Timesheets = () => {
  const { organisationId } = useContext(AuthContext); // Access organisationId
  const [selectedPayrollRun, setSelectedPayrollRun] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locations, setLocations] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState('');
  const [selectedStaffId, setSelectedStaffId] = useState('');
  const [popupDetails, setPopupDetails] = useState(null); // State to control the popup
  const [monthDetails, setMonthDetails] = useState([]);
  const [monthSummary, setMonthSummary] = useState([]);
  const [uniqueMonths, setUniqueMonths] = useState([]);
  const [selectedHalf, setSelectedHalf] = useState(null); // State for selected half (1st or 2nd Half)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [selectedLogAnomaly, setSelectedLogAnomaly] = useState(null);
  const [viewType, setViewType] = useState('Monthly'); // Default to Monthly
  const [selectedPeriod, setSelectedPeriod] = useState(null); // Selected period for detail display
  const [selectedDate, setSelectedDate] = useState(null);
  const [periodStart, setPeriodStart] = useState([]);
   const [firstHalf, setFirstHalf] = useState([]);
  const [secondHalf, setSecondHalf] = useState([]);

  // Refs to store fetched data
  const staffMembersRef = useRef([]);
  const staffHolidaysRef = useRef([]);
  const staffSicknessRef = useRef([]);
  const logEventsRef = useRef([]);
  const payRollRunsRef = useRef([]);
  // Fetch available locations
  const fetchLocations = useCallback(async (orgId) => {
    try {
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations');
      const filteredLocations = response.data.filter(location => location.organisation === orgId);
setLocations(filteredLocations); // Set t

      // Set selectedLocation to the _id of the first item in the locations array
//      if (response.data.length > 0) {
  //      setSelectedLocation(response.data[0]._id);

        //set start and end days
          // Conditional statement for period1 as a number
          setPeriodStart(response.data[0].startdate || new Date().toISOString());



    //load site settings
    setViewType(response.data[0].paycycle || "Monthly");
    
  //      setSelectedPeriod(null); // Reset selected period when view changes
    //  }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  }, []);


  
  const fetchPayRollRuns = useCallback(async () => {
    if (!selectedLocation) return;
  
    try {
      const params = {
        location_id: selectedLocation,
      };
  
      // Step 1: Fetch existing payroll runs
      const response = await axios.get(
        'https://heuristic-cray.194-76-27-167.plesk.page/api/payrollruns',
        { params }
      );
  
      // Store fetched data in the ref
      payRollRunsRef.current = response.data;
  
      // Step 2: Check if there are existing payroll runs
      let payrollRuns = payRollRunsRef.current;
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth(); // 0-11 (Jan = 0, Dec = 11)
      const currentYear = currentDate.getFullYear();
  
      // Helper function to add a new payroll run and also insert it into the collection via API
      const addPayrollRun = async (payrollDate) => {
        // Initialize payrollEndDate with the same value as payrollDate
        let payrollEndDate = new Date(payrollDate);
      
        // Adjust payrollEndDate based on viewType
        if (viewType === 'Monthly') {
          // Move the payrollEndDate to the same day next month and subtract 1 day
          payrollEndDate.setMonth(payrollDate.getMonth() + 1); // Move to the next month
          payrollEndDate.setDate(payrollDate.getDate() - 1); // Subtract 1 day to get the end date
        } else if (viewType === 'Fortnightly') {
          // Move the payrollEndDate forward by 14 days
          payrollEndDate.setDate(payrollEndDate.getDate() + 13); // 14 days total, including start day
        
        } else if (viewType === 'Weekly') {
          // Move the payrollEndDate forward by 14 days
          payrollEndDate.setDate(payrollEndDate.getDate() + 6); // 14 days total, including start day
        }
      
  
        const newEntry = {
          createdAt: new Date().toISOString(),
          location_id: selectedLocation,
          payrolldate: payrollDate.toISOString(),
          payrollend: payrollEndDate.toISOString(),
        };
  
        try {
          // Insert the new entry into the database
          await axios.post('https://heuristic-cray.194-76-27-167.plesk.page/api/payrollruns', newEntry);
  
          // If successful, add it to the local payrollRuns array
          payrollRuns.push(newEntry);
          payRollRunsRef.current = [...payrollRuns];
        } catch (error) {
          console.error('Error inserting new payroll entry:', error);
        }
      };
  
      // Step 3: Handle empty dataset
      if (payrollRuns.length === 0) {
        if (periodStart) {
          // Use period1 as the initial payrolldate
          const initialPayrollDate = new Date(periodStart);
          await addPayrollRun(initialPayrollDate);
  
          // Move to the next payroll date as per the viewType
          const now = new Date();
const twoMonthsLater = new Date(now.getFullYear(), now.getMonth() + 2, now.getDate());
          let nextPayrollDate = new Date(initialPayrollDate);
          while (true) {
            if (viewType === 'Monthly') {
              nextPayrollDate.setMonth(nextPayrollDate.getMonth() + 1);
            } else if (viewType === 'Fortnightly') {
              nextPayrollDate.setDate(nextPayrollDate.getDate() + 14);
            } else if (viewType === 'Weekly') {
              nextPayrollDate.setDate(nextPayrollDate.getDate() + 7);
            }
            // Stop if the next payroll date's month is greater than current month
            if (nextPayrollDate > twoMonthsLater) {
              break;
            }
  
            await addPayrollRun(nextPayrollDate);
          }
        }
      } else {
        // Step 4: Handle existing entries
        let lastPayroll = payrollRuns[payrollRuns.length - 1];
        let lastPayrollDate = new Date(lastPayroll.payrolldate);
        const now = new Date();
        const twoMonthsLater = new Date(now.getFullYear(), now.getMonth() + 2, now.getDate());
        // Check if the last payroll entry is up-to-date
        while (
          (lastPayrollDate < twoMonthsLater)  &&
          viewType
        ) {
          if (viewType === 'Monthly') {
            lastPayrollDate.setMonth(lastPayrollDate.getMonth() + 1);
          } else if (viewType === 'Fortnightly') {
            lastPayrollDate.setDate(lastPayrollDate.getDate() + 14);
          } else if (viewType === 'Weekly') {
            lastPayrollDate.setDate(lastPayrollDate.getDate() + 7);
          }
  
          await addPayrollRun(lastPayrollDate);
        }
      }

      payRollRunsRef.current.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


    } catch (error) {
      console.error('Error fetching payrollruns:', error);
    }
  }, [selectedLocation, viewType]);
  

  // Fetch staff members
  const fetchStaffMembers = useCallback(async () => {
    if (!selectedLocation) return;
    try {
      const params = {
        default_location_id: selectedLocation,
        active: true,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff', { params });
      staffMembersRef.current = response.data;
    } catch (error) {
      console.error('Error fetching staff members:', error);
    }
  }, [selectedLocation]);

  // Fetch staff holidays
  const fetchStaffHolidays = useCallback(async () => {
    if (!selectedLocation) return;
    try {
      const params = {
        organisation_id: selectedLocation,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday', { params });
      staffHolidaysRef.current = response.data;
    } catch (error) {
      console.error('Error fetching staff holidays:', error);
    }
  }, [selectedLocation]);

  // Fetch staff sickness records
  const fetchStaffSickness = useCallback(async () => {
    if (!selectedLocation) return;
    try {
      const params = {
        organisation_id: selectedLocation,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_sickness', { params });
      staffSicknessRef.current = response.data;
    } catch (error) {
      console.error('Error fetching staff sickness:', error);
    }
  }, [selectedLocation]);

  // Fetch log events
  const fetchLogEvents = useCallback(async (orgId) => {
    if (!selectedLocation) return;
    try {
      const params = {
        organisation_id: orgId,
        location: selectedLocation,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events_today', { params });
      logEventsRef.current = response.data;
    } catch (error) {
      console.error('Error fetching log events:', error);
    }
  }, [selectedLocation]);

  useEffect(() => {
    fetchLocations(organisationId);
  }, []); // Empty dependency array to run only once on mount
  
  useEffect(() => {
    if (selectedLocation) {
      fetchStaffMembers();
      fetchStaffHolidays();
      fetchStaffSickness();
      fetchPayRollRuns();
      fetchLogEvents(organisationId).then(() => {
        // Update unique months based on log events
        const months = Array.from(
          new Set(
            logEventsRef.current.map((event) =>
              new Date(event.created).toLocaleString('default', { month: 'long', year: 'numeric' })
            )
          )
        );
        setUniqueMonths(months);
      });
    }
  }, [selectedLocation]);
  


   
  const handleLocationChange = (event) => {
    const selectedLocationId = event.target.value;
    
    setSelectedLocation(selectedLocationId);
    setSelectedMonthYear('');
    setSelectedStaffId('');
    setMonthDetails([]);
    setMonthSummary([]);
  
    // Find the selected location object in the locations array
    const selectedLocation = locations.find(
      (location) => location._id === selectedLocationId
    );
  
    // If the location is found, set the payCycle
    if (selectedLocation) {

         setViewType(selectedLocation.paycycle);
        setSelectedPeriod(null); // Reset selected period when view changes
    
     }
     else{
        setViewType="Monthly";
        setSelectedPeriod(null);
     }
  };
  
 
 
  const handleMonthYearClick = async (startDate, endDate) => {
    // Set startDate to 00:01:00 and endDate to 23:59:00
    const start = new Date(startDate);
    start.setHours(0, 1, 0, 0);  // Set time to 00:01:00
  
    const end = new Date(endDate);
    end.setHours(23, 59, 0, 0);  // Set time to 23:59:00

    const startDateFormatted = `${start.getDate().toString().padStart(2, '0')}/${(start.getMonth() + 1).toString().padStart(2, '0')}/${start.getFullYear().toString().slice(-2)}`;
    const endDateFormatted = `${end.getDate().toString().padStart(2, '0')}/${(end.getMonth() + 1).toString().padStart(2, '0')}/${end.getFullYear().toString().slice(-2)}`;

    setSelectedPayrollRun(`${startDateFormatted}-${endDateFormatted}`);  // Set selected payroll run
  
    setSelectedStaffId('');
    await populateMonthDetails(start, end); // Pass the modified start and end dates
  };
  
  


  const handleStaffClick = (staffId) => {
    setSelectedStaffId(staffId);
  };

 
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return ''; // Return an empty string for invalid dates
    }
    
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  
   
  const handleExport = async (monthYear) => {
 
    const headers = ["Staff Name", "Role", "Std hrs worked", "Std pay", "Overtime hrs", "Overtime pay", "Days sick", "Days holiday"]; // Example headers
  
if (!monthSummary) return;
   
    const location = locations.find(loc => loc._id === selectedLocation);

    let locationName = 'Not found'; // Default value
    
    if (location) {
      locationName = location.name; // Extract the name field
    } else {
      console.log('Location not found');
    }
    
    // Create an array of arrays (row format) with headers and data
    const data = [
      [], // Blank line
      [{ v: `Time sheet summary for Site: ${locationName}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
      [],
      [{ v: `For: ${selectedPayrollRun}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
      [],
      [{ v: `Created on ${new Date().toLocaleString('en-GB', { timeZone: 'UTC' })}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
      [],
       headers, // Add headers as the first row
      ...monthSummary.map((item) => [
        item.staff_name,
        item.role,
        item.totalStdHours,
        item.totalStdPay,
        item.totalOvertimeHours,
        item.totalOvertimePay,
        item.daysSick,
        item.daysHoliday
      ])
    ];
      
    // Calculate totals for each column
    const totals = {
     totalStdHours: monthSummary.reduce((sum, item) => sum + item.totalStdHours, 0),
     totalStdPay: monthSummary.reduce((sum, item) => sum + item.totalStdPay, 0),
      totalOvertimeHours: monthSummary.reduce((sum, item) => sum + item.totalOvertimeHours, 0),
      totalOvertimePay: monthSummary.reduce((sum, item) => sum + item.totalOvertimePay, 0),
      daysHoliday: monthSummary.reduce((sum, item) => sum + item.daysHoliday, 0),
      daysSick: monthSummary.reduce((sum, item) => sum + item.daysSick, 0),
    };
  
    // Add a blank line before the totals
    data.push([], [
     { v: 'Totals:', s: { font: { bold: true } } }, // 'Totals:' label in bold
      '',
      totals.totalStdHours,
     totals.totalStdPay,
     totals.totalOvertimeHours,
     totals.totalOvertimePay,
     totals.daysSick,
     totals.daysHoliday
   ]);
  
    // Create a new worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(data); // aoa_to_sheet converts an array of arrays to a worksheet
  
    // Optional: Customize column widths
    worksheet['!cols'] = [
      { wch: 25 }, // Width for Staff Name column
      { wch: 10 }, // Width for Role column
      { wch: 20 }, // Width for Std hrs worked column
      { wch: 20 }, // Width for Std pay column
      { wch: 20 }, // Width for Overtime hrs column
      { wch: 20 }, // Width for Overtime pay column
      { wch: 20 }, // Width for Days sick column
      { wch: 20 }, // Width for Days holiday column
    ];
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Month Summary");
  
    // Export the workbook as an Excel file
    XLSX.writeFile(workbook, `MonthSummary_${selectedPayrollRun}.xlsx`);
  };
  



  const handleExportDetails = async (monthYear) => {
   
    const headers = ["Date", "Type", "Log in/out","Break", "Login comment","Logout comment","Hrs planned", "Std. hrs worked", "Std hrs pay", "Overtime hrs", "Overtime pay", "Status","Std -O/t hrly rates"]; // Example headers

    
//    await populateMonthDetails(selectedMonth, selectedYear);
  
if (!monthDetails) return;

monthDetails
  .filter((detail) => detail.staff_id === selectedStaffId)
  .sort((a, b) => {
  const dateA = new Date(a.date);
  const dateB = new Date(b.date);
  return dateA - dateB; // Sort in ascending order
});

    const location = locations.find(loc => loc._id === selectedLocation);

    let locationName = 'Not found'; // Default value
    
    if (location) {
      locationName = location.name; // Extract the name field
    } else {
      console.log('Location not found');
    }
    // get staff name from the first item
    let staffdetails=monthDetails[0]["staff_name"] + " Role:" + monthDetails[0]["role"];
     
    // Create an array of arrays (row format) with headers and data
    const data = [
      [], // Blank line
      [{ v: `Time sheet employee detail for Site: ${locationName}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
      [],
      [{ v: `For: ${selectedPayrollRun}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
      [],
      [{ v: `Created on ${new Date().toLocaleString('en-GB', { timeZone: 'UTC' })}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
      [],
      [{ v: `Staff name: ${staffdetails}`, s: { font: { bold: true, sz: 14 } } }], // Staff name in bold and larger font
      [],
       headers, // Add headers as the first row
      ...monthDetails.map((item) => [
       
        item.dayname + ' ' + new Date(item.date).getDate().toString().padStart(2, '0') + '/' + (new Date(item.date).getMonth() + 1).toString().padStart(2, '0'), // Format date as DD/M
        item.type,
        formatTime(item.actual_login) + "-" + formatTime(item.actual_logout),
        item.breaklength,
        item.login_comment,
        item.logout_comment,
        item.hrsPlanned,
        item.stdhrsworked,
        item.stdhrspay,
        item.overtimehrs,
        item.overtimepay,
        item.status,
        '£'+ item.stdhrspay + '- £' + item.overtimepay
      ])
    ];
    
    

    // Calculate totals for each column
    const totals = {
      totalStdHours: monthSummary.reduce((sum, item) => sum + item.stdhrsworked, 0),
      totalStdPay: monthSummary.reduce((sum, item) => sum + item.stdhrspay, 0),
      totalOvertimeHours: monthSummary.reduce((sum, item) => sum + item.overtimehrs, 0),
      totalOvertimePay: monthSummary.reduce((sum, item) => sum + item.overtimepay, 0),
     };
  
    // Add a blank line before the totals
 //  data.push([], [
  //    { v: 'Totals:', s: { font: { bold: true } } }, // 'Totals:' label in bold
   //   '','','','',
    //  totals.totalStdHours,
     // totals.totalStdPay,
     // '',
    //  totals.totalOvertimeHours,
    //  totals.totalOvertimePay,
    //     ]);
  
    // Create a new worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(data); // aoa_to_sheet converts an array of arrays to a worksheet
  
    // Optional: Customize column widths
    worksheet['!cols'] = [
      { wch: 15 }, // Width for Staff Name column
      { wch: 10 }, // Width for Role column
      { wch: 10 }, // Width for Std hrs worked column
      { wch: 20 }, // Width for Std pay column
      { wch: 20 }, // Width for Overtime hrs column
      { wch: 20 }, // Width for Overtime pay column
      { wch: 20 }, // Width for Days sick column
      { wch: 20 }, // Width for Days holiday column
      { wch: 20 }, // Width for Overtime hrs column
      { wch: 20 }, // Width for Overtime pay column
      { wch: 20 }, // Width for Days sick column
      { wch: 20 }, // Width for Days holiday column
   
    ];
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Month Details");
  
    // Export the workbook as an Excel file
    XLSX.writeFile(workbook, `MonthDetails_${staffdetails}_${selectedPayrollRun}.xlsx`);
  };
  

  
  


  const handleExportAllDetails = async (monthYear) => {
    const headers = ["Date", "Type", "Log in/out","Break", "Login comment","Logout comment","Hrs planned", "Std. hrs worked", "Std hrs pay", "Overtime hrs", "Overtime pay", "Status","Std - O/t hrly rates"]; // Example headers
  
    if (!monthDetails) return;
  
    // Step 1: Find all unique staff IDs in monthDetails
    const staffIds = [...new Set(monthDetails.map(detail => detail.staff_id))];
  
    // Step 2: Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Step 3: Loop through each staff ID and generate their sheet
    staffIds.forEach((staffId) => {
      // Filter details for the current staff
      const staffDetails = monthDetails
        .filter((detail) => detail.staff_id === staffId)
        .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date
  
      if (staffDetails.length === 0) return; // Skip if no details for the staff
  
      // Get location and staff info
      const location = locations.find(loc => loc._id === selectedLocation);
      const locationName = location ? location.name : 'Not found';
      const staffHeader = `${staffDetails[0].staff_name} - Role: ${staffDetails[0].role}`;
  
  

      // Step 4: Prepare the data for this staff's sheet
      const data = [
        [], // Blank line
        [{ v: `Time sheet full staff details for Site: ${locationName}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
        [],
        [{ v: `For: ${selectedPayrollRun}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
        [],
        [{ v: `Created on ${new Date().toLocaleString('en-GB', { timeZone: 'UTC' })}`, s: { font: { bold: true, sz: 14 } } }], // Location name in bold and larger font
        [],
           headers, // Add headers as the first row
        ...staffDetails.map((item) => [
          `${item.dayname} ${new Date(item.date).getDate().toString().padStart(2, '0')}/${(new Date(item.date).getMonth() + 1).toString().padStart(2, '0')}`, // Date in DD/MM format
          item.type,
          formatTime(item.actual_login) + "-" + formatTime(item.actual_logout),
          item.breaklength,
          item.login_comment,
          item.logout_comment,
          item.hrsPlanned,
          item.stdhrsworked,
          item.stdhrspay,
          item.overtimehrs,
          item.overtimepay,
          item.status,
          '£'+ item.stdhrspay + '- £' + item.overtimepay
        ])
      ];
  
      // Step 5: Create a worksheet for this staff
      const worksheet = XLSX.utils.aoa_to_sheet(data);
  
      worksheet['!cols'] = [
        { wch: 15 }, // Width for Staff Name column
        { wch: 10 }, // Width for Role column
        { wch: 10 }, // Width for Std hrs worked column
        { wch: 20 }, // Width for Std pay column
        { wch: 20 }, // Width for Overtime hrs column
        { wch: 20 }, // Width for Overtime pay column
        { wch: 20 }, // Width for Days sick column
        { wch: 20 }, // Width for Days holiday column
        { wch: 20 }, // Width for Overtime hrs column
        { wch: 20 }, // Width for Overtime pay column
        { wch: 20 }, // Width for Days sick column
        { wch: 20 }, // Width for Days holiday column
     
      ];
    
    
      // Step 6: Append this worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, staffDetails[0].staff_name); // Use staff name as sheet name
    });
  
    // Step 7: Export the workbook as an Excel file
    XLSX.writeFile(workbook, `MonthDetails_${selectedPayrollRun}.xlsx`);
  };
  


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric' })}`;
  };

  const formatDateTime = (input) => {
    if (!input) return 'Invalid Date';
    const date = new Date(input);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const editSummaryItem = (ind) => {
    // You can now use the 'ind' variable which represents the index of the monthSummary array
    console.log("Editing item at index:", ind);
    
    // Add your logic here to handle the editing of the summary item
  };
    const handleDialogClose = () => {
    setShowReviewDialog(false);
    // Logic to refresh the logAnomalies grid if needed
     
  };


  const openLogEventReview = async (anomaly) => {
    if (anomaly == null || anomaly=='') {
      return;
    }
  
    // Get log event and load into anomaly
    try {
      const params = {
        itemid: anomaly,
      };
      const response = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/staff_log_events', { params });
  
      // Check if data exists and has at least one item
      if (response.data && response.data.length > 0) {
        // Extract the first item from the response data

        const logEvent = response.data[0];

         
      
        // Store the selected log anomaly in the state
        setSelectedLogAnomaly(logEvent);
        
        // Trigger the dialog to open
        setShowReviewDialog(true);
      }
    } catch (error) {
      console.error("Error fetching log event:", error); // Optionally handle errors
    }
  };
  
  
  const formatLocalDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;  // Format: yyyy-mm-dd
  };
  
   
// Function to populate monthDetails
const populateMonthDetails = (startDate, endDate) => {
  return new Promise((resolve) => {
    let monthDetailsArray = [];

    // Get staff members
    const staffMembers = staffMembersRef.current;

    // Cycle through each staff member
    staffMembers.forEach((staffMember) => {
      const staff_id = staffMember._id;
      const role = staffMember.role;
      const hrsmth = staffMember.contracted_hours_per_month;
      const staff_name = `${staffMember.firstName} ${staffMember.lastName}`;
      const hourlyPayRate = staffMember.hourlyPayRate;
      const dailyEntries = staffMember.dailyEntries;

      // Cycle through each day between startDate and endDate
      for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {

        const dayname = date.toLocaleString('en-GB', { weekday: 'short' }); // 'Mon', 'Tue', etc.
        let weekNumber = Math.ceil(date.getDate() / 7);
        if (weekNumber > 4) weekNumber = 1; // If week 5, call it week 1

        const dailyEntry = dailyEntries.find(
          (entry) => entry.dayOfWeek === dayname && entry.weekNumber === weekNumber
        );

        let type = 'Not scheduled';
        let hrsPlanned = 0;
        if (dailyEntry && dailyEntry.hoursworked > 0) {
          type = 'Scheduled';
          hrsPlanned = dailyEntry.hoursworked;
        }

        monthDetailsArray.push({
          staff_name,
          role,
          hourlyPayRate,
          hrsmth,
          staff_id,
          date: formatLocalDate(date),
       dayname,
          type,
          hrsPlanned,
          stdhrsworked: 0,
          stdhrspay: 0,
          overtimehrs: 0,
          overtimepay: 0,
          payadjust: 0,
          comment: "",
          itemid: "",
          status: "",
        });
      }
    });

    // Process staff sickness
    const staffSickness = staffSicknessRef.current;
    staffSickness.forEach((sickness) => {
      const { staff_id, created, rtw_date } = sickness;
      if (!rtw_date) return; // Skip if rtw_date is null or empty
      const sicknessStart = new Date(created);
      const sicknessEnd = new Date(rtw_date);

      for (let d = new Date(sicknessStart); d <= sicknessEnd; d.setDate(d.getDate() + 1)) {
        if (d >= startDate && d <= endDate) {
          const dateStr = d.toISOString().split('T')[0];
          const monthDetail = monthDetailsArray.find(
            (md) => md.staff_id === staff_id && md.date === dateStr
          );
          if (monthDetail) {
            monthDetail.type = 'Sickness';
          }
        }
      }
    });

    // Process staff holidays
    const staffHolidays = staffHolidaysRef.current;
    staffHolidays.forEach((holiday) => {
      const { staff_id, start, end, hourlyPayRate, stdhrsworked, unpaid } = holiday;
      const holidayStart = new Date(start);
      const holidayEnd = new Date(end);

      for (let d = new Date(holidayStart); d <= holidayEnd; d.setDate(d.getDate() + 1)) {
        if (d >= startDate && d <= endDate) {
          const dateStr = d.toISOString().split('T')[0];
          const monthDetail = monthDetailsArray.find(
            (md) => md.staff_id === staff_id && md.date === dateStr
          );
          if (monthDetail && monthDetail.type === 'Scheduled') {
            if (unpaid === true) {
              monthDetail.type = 'Holiday - unpaid';
              monthDetail.stdhrspay = 0;
            } else {
              monthDetail.type = 'Holiday';
              monthDetail.stdhrsworked = monthDetail.hrsPlanned;
              monthDetail.stdhrspay = monthDetail.hrsPlanned * monthDetail.hourlyPayRate;
            }
          }
        }
      }
    });

    // Process log events
    const logEvents = logEventsRef.current.filter((event) => {
      const eventDate = new Date(event.created);
      return eventDate >= startDate && eventDate <= endDate;
    });

    logEvents.forEach((logEvent) => {
      const { breaklength, actual_login, actual_logout, login_comment, logout_comment, _id, staff_id, staff_name, status, created, stdhrsworked, stdhrspay, overtimehrs, overtimepay } = logEvent;
      const dateStr = new Date(created).toISOString().split('T')[0];
      const monthDetail = monthDetailsArray.find(
        (md) => md.staff_id === staff_id && md.date === dateStr
      );

      if (monthDetail && monthDetail.type !== 'Worked' && monthDetail.type !== 'Extra Session') {
        monthDetail.type = 'Worked';
        monthDetail.stdhrsworked = stdhrsworked;
        monthDetail.stdhrspay = stdhrsworked * stdhrspay;
        monthDetail.overtimehrs = overtimehrs;
        monthDetail.overtimepay = overtimehrs * overtimepay;
        monthDetail.itemid = _id;
        monthDetail.status = status;
        monthDetail.actual_login = actual_login;
        monthDetail.actual_logout = actual_logout;
        monthDetail.login_comment = login_comment;
        monthDetail.logout_comment = logout_comment;
        monthDetail.breaklength = breaklength;
      } else if (!monthDetail) {
        const dayname = new Date(created).toLocaleString('en-GB', { weekday: 'short' });
        monthDetailsArray.push({
          staff_name,
          staff_id,
          date: dateStr,
          dayname,
          itemid: _id,
          type: 'Extra Session',
          stdhrsworked,
          stdhrspay: stdhrsworked * stdhrspay,
          overtimehrs,
          overtimepay: overtimehrs * overtimepay,
          actual_login,
          actual_logout,
          login_comment,
          logout_comment,
          breaklength,
          status
        });
      }
    });

    monthDetailsArray = monthDetailsArray.filter(entry => entry.type !== 'Not scheduled');

    // Update state
    setMonthDetails(monthDetailsArray);

    // Call populateMonthSummary and resolve the promise after updating the state
    populateMonthSummary(monthDetailsArray);
    
    // Resolve the promise to indicate that the function has completed
    resolve();
  });
};


  
  // Function to populate monthSummary
  const populateMonthSummary = (monthDetailsArray) => {
    const summary = {};

    monthDetailsArray.forEach((detail) => {
      const {
        staff_id,
        staff_name,
        role,
        hrsmth,
        stdhrsworked,
        stdhrspay,
        overtimehrs,
        overtimepay,
        type,
      } = detail;
      if (!summary[staff_id]) {
        summary[staff_id] = {
          staff_id,
          staff_name,
          role,
          hrsmth,
          totalStdHours: 0,
          totalStdPay: 0,
          totalOvertimeHours: 0,
          totalOvertimePay: 0,
          daysHoliday: 0,
          daysSick: 0,
        };
      }
      summary[staff_id].totalStdHours += stdhrsworked || 0;
      summary[staff_id].totalStdPay += stdhrspay || 0;
      summary[staff_id].totalOvertimeHours += overtimehrs || 0;
      summary[staff_id].totalOvertimePay += overtimepay || 0;
      if (type === 'Holiday') {
        summary[staff_id].daysHoliday += 1;
      }
      if (type === 'Sickness') {
        summary[staff_id].daysSick += 1;
      }
    });

    const monthSummaryArray = Object.values(summary).sort((a, b) =>
      a.staff_name.localeCompare(b.staff_name)
    );

    setMonthSummary(monthSummaryArray);
  };


 




  return (
    <div>
      {/* Top Section: Timesheets for and Location Select */}
      <div>
        <h1 style={{ display: 'inline', marginRight: '10px', fontSize: '14px' }}>Timesheets for</h1>
        <div style={{ display: 'inline-block' }}>
          <select 
            onChange={handleLocationChange}
            style={{
              fontSize: '14px',
              height: '28px',
              padding: '4px',
            }}
          >
            <option value="">Select Location</option>
            {locations.map((location) => (
              <option key={location._id} value={location._id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
      </div>


       {/* Main Content: Payroll Periods and Staff Summary */}
      <div className="log-events-container">
 {/* Left Panel: Payroll Periods */}
<div className="log-event-column" style={{ paddingTop: '33px' }}>
  {/*  <h2>{viewType} Periods</h2> */}
  {payRollRunsRef.current && payRollRunsRef.current.length > 0 ? (
    payRollRunsRef.current.map((payrollRun, index) => {
      const payrollStart = new Date(payrollRun.payrolldate);
      const payrollEnd = new Date(payrollRun.payrollend);

      // Format both dates as 'DD/MM/YY'
      const startDateFormatted = `${payrollStart.getDate().toString().padStart(2, '0')}/${(payrollStart.getMonth() + 1).toString().padStart(2, '0')}/${payrollStart.getFullYear().toString().slice(-2)}`;
      const endDateFormatted = `${payrollEnd.getDate().toString().padStart(2, '0')}/${(payrollEnd.getMonth() + 1).toString().padStart(2, '0')}/${payrollEnd.getFullYear().toString().slice(-2)}`;

      const payrollRunKey = `${startDateFormatted}-${endDateFormatted}`; // String format for comparison

      return (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
            backgroundColor: selectedPayrollRun === payrollRunKey ? 'lightblue' : 'transparent', // Compare using the formatted string
            border: '1px solid black',
          }}
        >
          <div
            onClick={() => handleMonthYearClick(payrollStart, payrollEnd)} // Pass both start and end dates
            style={{ cursor: 'pointer', flexGrow: 1 }}
          >
            {startDateFormatted} - {endDateFormatted}
          </div>
        </div>
      );
    })
  ) : (
    <div>No payroll periods available</div>
  )}
</div>
 
  
      {/* Middle Panel: Month Summary */}
      <div className="month-summary-column">
        {/* Export buttons */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
          <button onClick={() => handleExport(selectedMonthYear)}>
            Export Summary
          </button>
          <button onClick={() => handleExportAllDetails()} style={{ marginLeft: '10px' }}>
            Export All Details
          </button>
        </div>
  
   {/*       <h2>Staff Summary</h2>  */}
        {monthSummary.length > 0 ? (
          monthSummary.map((summary) => {
            const hasNonCompletedEntry = monthSummary.some(
              (detail) => detail.staff_id === summary.staff_id && detail.type !== 'Completed'
            );
  
            return (
              <div
                key={summary.staff_id}
                onClick={() => handleStaffClick(summary.staff_id)}
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  display: 'flex',
                  border: '1px solid black',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: selectedStaffId === summary.staff_id
                    ? 'lightblue'
                    : hasNonCompletedEntry
                    ? 'lightcoral'
                    : 'lightgreen',
                }}
              >
                <div className="staff-summary">
                  <div className="staff-name">{summary.staff_name}</div>
                  <div className="staff-role">{summary.role}</div>
                  <div className="summary-std-hours">
                    Cont: £{summary.totalStdPay.toFixed(2)} ({summary.totalStdHours}hrs/{summary.hrsmth}hrs)
                  </div>
                  {summary.totalOvertimeHours > 0 && (
                    <div className="summary-overtime">
                      O/T: £{summary.totalOvertimePay.toFixed(2)}
                    </div>
                  )}
                  <div className="summary-sick-holiday">
                    {summary.daysSick > 0 && <div>Sick: {summary.daysSick}</div>}
                    {summary.daysHoliday > 0 && <div>Hols: {summary.daysHoliday}</div>}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No data available for this month/year.</div>
        )}
      </div>
  
      {/* Right Panel: Month Details for selected staff */}
      <div className="month-details-column">
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
          <button onClick={handleExportDetails}>
            Export
          </button>
        </div>
  
    
      {/*     <h2>Staff Details</h2>*/}
        {selectedStaffId && (
          <div>
            {monthDetails
              .filter((detail) => detail.staff_id === selectedStaffId)
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .map((detail) => {
                let backgroundColor = 'transparent';
  
                if (detail.type === 'Worked') {
                  if (detail.status === 'Completed') {
                    backgroundColor = 'lightgreen';
                  } else {
                    backgroundColor = 'lightcoral';
                  }
                }
                if (detail.type === 'Holiday') {
                  backgroundColor = 'lightgreen';
                }
                return (
                  <div
                    key={detail.date}
                    style={{ display: 'flex', padding: '5px', backgroundColor, border: '1px solid black' }}
                    onClick={() => openLogEventReview(detail.itemid)}
                  >
           <div className="item-section day">
  {detail.dayname}, 
  {new Date(detail.date).getDate().toString().padStart(2, '0')}/{(new Date(detail.date).getMonth() + 1).toString().padStart(2, '0')}
</div>
          <div className="item-section type">{detail.type}</div>
                    {detail.stdhrspay !== 0 && (
                      <div className="item-section stdpay">
                        Std Pay: £{detail.stdhrspay.toFixed(2)} Hours: {detail.stdhrsworked} / {detail.hrsPlanned}
                      </div>
                    )}
                    {detail.overtimepay > 0 && (
                      <div className="item-section overtime">
                        Overtime Pay: £{detail.overtimepay.toFixed(2)} Hrs: {detail.overtimehrs}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
   </div>
      {/* Period Details */}
      {selectedPeriod && (
        <div className="period-details">
          <h4>Details for {viewType === 'Monthly' ? `${selectedPeriod.month} ${selectedPeriod.year}` : selectedPeriod.period}</h4>
          <p>Total Hours: {selectedPeriod.totalHours}</p>
          <p>Total Overtime: {selectedPeriod.totalOvertime}</p>
        </div>
      )}
  
      {/* Popup for log event review */}
      {showReviewDialog && (
        <ReviewLogEvent
          isOpen={showReviewDialog}
          onClose={handleDialogClose}
          logEvent={selectedLogAnomaly}
        />
      )}
    </div>
  );
  
}  
export default Timesheets;
