import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './login.css'; // Import the CSS file

const Login = () => {
  const { setStartDate, setIsLoggedIn, setOrganisationId, setUserRole } = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Dummy authentication logic
    let orgId;
    let userRole;
    if (username === 'Lyneham' && password === 'Pharmace2023') {
      orgId = '66c285a67a5d05eff58e0109';
      userRole = 'Admin';
    } else if (username === 'GL-manager' && password === 'GLM2023') {
      orgId = '66f2a50aeaaa22da74c9dd80';
      userRole = 'Manager';
    } else if (username === 'GL-admin' && password === 'GLA2023') {
      orgId = '66f2a50aeaaa22da74c9dd80';
      userRole = 'Admin';
    } else if (username === 'Southtown' && password === 'Pharmace2023') {
      orgId = '66f84ac4fd5c9470d1ae71bf';
      userRole = 'Admin';
    }

    if (orgId) {
      setIsLoggedIn(true); // This updates the context state
      setOrganisationId(orgId);
      setUserRole(userRole);
      navigate('/homepage'); // Navigate to homepage
    } else {
      // Optionally, you can handle invalid login attempts here
      alert('Invalid username or password');
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <div style={{ marginBottom: '20px', paddingTop: '30px' }}>
          {/* Padding and margin styles */}
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div style={{ marginBottom: '20px', paddingTop: '5px' }}>
          {/* Padding and margin styles */}
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
      {/* Link to download the file */}
      <div style={{ marginTop: '20px' }}>
        <a
          href="https://zen-williamson.194-76-27-167.plesk.page/psp.apk"
          download
          style={{
            textDecoration: 'none',
            color: 'blue',
            fontWeight: 'bold',
            display: 'block',
            marginTop: '20px',
          }}
        >
          Download App
        </a>
      </div>
    </div>
  );
};

export default Login;
