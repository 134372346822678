import React, { useState, useContext } from 'react';
import axios from 'axios';
import './reviewLogEvent.css'; // Adjust the path as needed
import MapDialog from './Map'; // Import the new MapDialog
import { AuthContext } from './AuthContext';

const ReviewLogEvent = ({ isOpen, onClose, logEvent, fetchStaffLogEvents }) => {
  const [isLocationDialogOpen, setLocationDialogOpen] = useState(false);
  const [isLogoutDistanceDialogOpen, setLogoutDistanceDialogOpen] = useState(false);
  const [stdhrsworked, setStdHrsWorked] = useState(logEvent.stdhrsworked);
  const [overtimehrs, setOvertimeHrs] = useState(logEvent.overtimehrs);
  const [managerComment, setManagerComment] = useState(logEvent.manager_comment);
  const [holidayStatus, setHolidayStatus] = useState(logEvent.status);
  const { userRole } = useContext(AuthContext); // Access organisationId
  const [errorMessage, setErrorMessage] = useState('');




  if (!isOpen) {
    setLocationDialogOpen(false);
    setLogoutDistanceDialogOpen(false);
    return null;
  }


 

  const formatDate = (input) => {
    if (!input) return 'Invalid Date';
    const date = (typeof input === 'string') ? new Date(input) : input;
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const formatDateTime = (input) => {
    if (!input) return 'Invalid Date';
    const date = (typeof input === 'string') ? new Date(input) : input;
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const handleSave = async () => {


    if (logEvent.role === 'Pharmacist' && userRole === 'Manager') {
      setErrorMessage('You are not authorised to complete this log event');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);  // Hide after 3 seconds
      return;
    }


    const updatedLogEvent = {
      _id: logEvent._id,
      stdhrsworked: stdhrsworked,
      overtimehrs: overtimehrs,
      manager_comment: managerComment,
      status: holidayStatus,

    };

    try {
      await axios.post(`https://heuristic-cray.194-76-27-167.plesk.page/api/log_event_update`, updatedLogEvent);
       
      onClose(); // Close the dialog
    } catch (error) {
      console.error('Error updating log event:', error);
    }
  };

  const renderTopLeftPanel = () => (
    <div className="panel">
      <h3>Background</h3>
      <p><strong>Staff name:</strong> {logEvent.staff_name}</p>
      <p><strong>Hour pattern:</strong> {logEvent.hourpattern}</p>
      <p><strong>Geolocate:</strong> {logEvent.usegeolocate}</p>
      <p><strong>Location:</strong> {logEvent.location}</p>
      <p><strong>Date:</strong> {formatDate(logEvent.created)}</p>
      <p><strong>Week of cycle:</strong> {logEvent.weekNumber}</p>
      <p><strong>Day of week:</strong> {new Date(logEvent.created).toLocaleDateString('en-US', { weekday: 'long' })}</p>
      <p><strong>Planned hours:</strong> {logEvent.planned_login}-{logEvent.planned_logout}</p>
      <p><strong>Break length:</strong> {logEvent.breaklength}</p>
      <p><strong>Grace period:</strong> {logEvent.graceperiod}</p>
     
  
 {/* Moved radio buttons panel here */}
<div onChange={(e) => setHolidayStatus(e.target.value)} className="radio-group" style={{ paddingTop: '20px' }}>
  <label style={{ display: 'inline-flex', alignItems: 'center', marginRight: '20px', paddingBottom: '10px' }}>
    Completed
    <input
      type="radio"
      name="status"
      value="Completed"
      checked={holidayStatus === 'Completed'}
      style={{ marginLeft: '5px' }} // Space between label and radio button
    /> 
  </label>

  <label style={{ display: 'inline-flex', alignItems: 'center', paddingBottom: '10px' }}>
    Under Review
    <input
      type="radio"
      name="status"
      value="Under Review"
      checked={holidayStatus === 'Under Review'}
      style={{ marginLeft: '5px' }} // Space between label and radio button
    /> 
  </label>
</div>

      <div className="popup-buttons">
          <button className="confirm-button" onClick={handleSave}>Submit</button>
          <button className="cancel-button" onClick={() => { 
            onClose(); 
            setLocationDialogOpen(false); 
            setLogoutDistanceDialogOpen(false); 
          }}>Cancel</button>
        </div>


    </div>
  

);
  

 
const renderPanel = () => (
  <div className="panel">
    {/* Left Panel Content */}
    <div className="left-panel">
      <p
        style={{
          backgroundColor: logEvent.locationValidated ? 'inherit' : 'lightcoral',
        }}
      >
        <strong>Clock-in location:</strong> {logEvent.location} &nbsp;
        <span
          className={`clickable ${logEvent.login_latitude && logEvent.login_longitude ? 'highlight' : ''}`}
          onClick={() => setLocationDialogOpen(true)}
        >
          {logEvent.locationValidated ? 'Validated' : 'Not Validated'}
        </span>
      </p>

      <p
        style={{
          backgroundColor: logEvent.login_variance > logEvent.graceperiod ? 'lightcoral' : 'inherit',
        }}
      >

<div style={{ paddingBottom: '10px' }}></div>
        <strong>Actually  logged in at location at:</strong> {formatDateTime(logEvent.actual_login)} <br/> 
        <strong>Start time used to calculate hours:</strong> {formatDateTime(logEvent.adjusted_login)} &nbsp;
        {logEvent.login_variance > 0 ? (
          <p><strong>Late by:</strong> {logEvent.login_variance} minutes</p>
        ) : null}
      </p>
      {logEvent.login_variance > logEvent.graceperiod ? (
  <p>
    <strong>Start time adjusted because arrived beyond grace period</strong>
  </p>
) : null}

{logEvent.login_variance > 0 && logEvent.login_variance < logEvent.graceperiod ? (
  <p>
    <strong>Arrived during grace period, so start time set to planned start time</strong>
  </p>
) : null}

{logEvent.login_variance < 0   ? (
  <p>
    <strong>Arrived early, start time set to planned start time.</strong>
  </p>
) : null}


      <div style={{ paddingBottom: '10px' }}></div>
      <p><strong>Clock-in message:</strong> {logEvent.login_message}</p>
      <p><strong>Clock-in response:</strong> {logEvent.login_comment}</p>

      <p style={{ margin: '10px 0' }}></p>

      <div
  className={`clickable ${logEvent.logout_latitude && logEvent.logout_longitude ? 'highlight' : ''}`}
  style={{
    backgroundColor: 
    (logEvent.ringFence === null || logEvent.ringFence === 0 || logEvent.ringFence === undefined) 
      ? (logEvent.logoutdistance_variance > 25 ? 'lightcoral' : 'inherit')
      : (logEvent.logoutdistance_variance > logEvent.ringFence ? 'lightcoral' : 'inherit'), // Apply lightcoral based on the new condition
  display: 'inline-flex', // Keep it on the same line
    alignItems: 'center', // Vertically align the items in the center
    padding: '5px', // Padding for spacing
    borderRadius: '5px', // Optional: rounded corners for the div
  }}
  onClick={() => setLogoutDistanceDialogOpen(true)}
>
  <strong style={{
    backgroundColor: 'inherit', // Ensure strong text doesn't override the background
    color: 'black', // Set the text color to black
    fontSize: '14px', // Increase the font size for the text
    paddingRight: '5px', // Optional: space between the text and the span
  }}>
    Distance away at clock-out (metres):
  </strong>
  <span style={{
    backgroundColor: 'blue',
    color: 'white',
    padding: '3px',
    borderRadius: '3px',
    marginLeft: '5px', // Optional: space between the text and value
  }}>
    {logEvent.logoutdistance_variance}
  </span>
</div>



      <p
        style={{
          backgroundColor: logEvent.logout_variance < 0 || logEvent.logout_variance > logEvent.graceperiod ? 'lightcoral' : 'inherit',
        }}
      >
          <div style={{ paddingBottom: '10px' }}></div>
         <strong>Actual log out:</strong> {formatDateTime(logEvent.actual_logout)} <br/>
        {logEvent.logout_variance < 0 ? (
          <>
            <strong>Logged out early by:</strong> {Math.abs(logEvent.logout_variance)} minutes
          </>
        ) : logEvent.logout_variance > logEvent.graceperiod ? (
          <>
            <strong>Logged out late by:</strong> {logEvent.logout_variance} minutes
          </>
        ) : (
          <>
            <strong></strong>
          </>
        )}
      </p>
      <div style={{ paddingBottom: '10px' }}></div>
      <p><strong>Clock-out message:</strong> {logEvent.logout_message}</p>
      <p><strong>Clock-out response:</strong> {logEvent.logout_comment}</p>

      {logEvent.overtimerequested !== undefined && (
  <p style={{ backgroundColor: 'lightcoral', display: 'flex', alignItems: 'center', padding: '5px' }}>
    <strong style={{ marginRight: '8px' }}>Overtime requested:</strong>
    <input 
      type="checkbox" 
      checked={logEvent.overtimerequested} 
      readOnly 
    />
  </p>
)}

    </div>

    {/* Right Panel Content */}
    <div className="right-panel">
      {/*    <h3>Summary</h3> */}
      <p><strong>Actual hrs on site (including break):</strong> {logEvent.hoursonsite}  should have been {logEvent.plannedhoursonsite}</p>
      <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <p style={{ margin: 0 }}><strong>Hrs to go on timesheet: </strong></p>
        <input
          type="text"
          value={stdhrsworked}
          onChange={(e) => setStdHrsWorked(e.target.value)}
          style={{ padding: '5px', width: '100px' }} // Adjust input field size if needed
        />

        <p style={{ margin: 0 }}><strong>Overtime hrs for timesheet: </strong></p>
        <input
          type="text"
          value={overtimehrs}
          onChange={(e) => setOvertimeHrs(e.target.value)}
          style={{ padding: '5px', width: '100px' }} // Adjust input field size if needed
        />
      </div>

      <p><strong>Action: Review times/comments. Adjust timesheet hours, enter a comment and mark 'completed'. Submit when finished.:</strong></p>
      <textarea
        value={managerComment}
        onChange={(e) => setManagerComment(e.target.value)} 
        rows="4"
        cols="50"
      />
    </div>
  </div>
);


const renderDetails = () => (
  <div className="details-panel">
  <div className="hour-pattern-content">
  <h3>ENTRY DETAILS:</h3>
  <div className="row">
      <h3>Source/type of entry:</h3>
      <p>{logEvent.origin}</p>
    </div>
    <div className="row">
      <h3>Day analysis:</h3>
      <p>{logEvent.valid}</p>
    </div>

    <h3>LOGIN DETAILS:</h3>
     <div className="row">
  <h3>NFC used for login:</h3>
  <p>
    <input type="checkbox" checked={logEvent.login_rfid_used} readOnly />
  </p>
</div>  
{logEvent.login_rfid_used && (
  <div className="row">
    <h3>Login location by NFC:</h3>
    <p>{logEvent.login_rfid_location}</p>
  </div>
)}
{!logEvent.login_rfid && (
  <>
    <div className="row">
      <h3>Login Location by GPS:</h3>
      <p>{logEvent.location}</p>
    </div>
    <div className="row">
      <h3>Login latitude:</h3>
      <p>{logEvent.login_latitude}</p>
    </div>
    <div className="row">
      <h3>Login longitude:</h3>
      <p>{logEvent.login_longitude}</p>
    </div>
  </>
)}

<h3>LOGOUT DETAILS:</h3>

    <div className="row">
  <h3>NFC used for logout:</h3>
  <p>
    <input type="checkbox" checked={logEvent.logout_rfid_used} readOnly />
  </p>
</div>
{logEvent.logout_rfid_used && (
  <div className="row">
    <h3>Logout location by NFC:</h3>
    <p>{logEvent.logout_rfid_location}</p>
  </div>
)}

{!logEvent.logout_rfid_used && (
  <>
    <div className="row">
      <h3>Logout location by GPS:</h3>
      <p>{logEvent.logout_geoloc_location}</p>
    </div>
    <div className="row">
      <h3>Logout latitude:</h3>
      <p>{logEvent.logout_latitude}</p>
    </div>
    <div className="row">
      <h3>Logout longitude:</h3>
      <p>{logEvent.logout_longitude}</p>
    </div>
  </>
)}

  </div>
</div>

);

  return (
    <div className="popup-overlay">
      <div className="popup">
         <div className="popup-content">
          <div className="column">
            {renderTopLeftPanel()}
          </div>
          <div className="column">
  {renderPanel()}  {/* Replaces the old panel rendering functions */}
</div>
<div className="column">
  {renderDetails()}  {/* Replaces the old panel rendering functions */}
</div>
         
        </div>

      </div>

      {isLocationDialogOpen && (
        <MapDialog
           isOpen={isLocationDialogOpen}
          onClose={() => setLocationDialogOpen(false)}
          latitude={logEvent.login_latitude}
          longitude={logEvent.login_longitude}
          itemid='1'
        />
      )}


{errorMessage && (
  <div className="error-message" style={{ color: 'red', padding: '10px', background: '#f8d7da', border: '1px solid #f5c6cb', marginBottom: '10px' }}>
    {errorMessage}
  </div>
)}



      {isLogoutDistanceDialogOpen && (
        <MapDialog
           isOpen={isLogoutDistanceDialogOpen}
          onClose={() => setLogoutDistanceDialogOpen(false)}
          latitude={logEvent.logout_latitude}
          longitude={logEvent.logout_longitude}
        />
      )}
    </div>
  );
};

export default ReviewLogEvent;
