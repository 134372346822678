import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import PlanningDialog from './PlanningDialog'; // Import the HolidayDialog component
import './Planning.css';
import FloatingPopup from './FloatingPopup';
import FullLocums from './FullLocums';
import { AuthContext } from './AuthContext';


const Planning = () => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  
  const staffMembers = useRef([]);

  const { organisationId } = useContext(AuthContext); // Access organisationId

  const [staffHolidays, setStaffHolidays] = useState([]);
  const [locums, setLocums] = useState([]);
  const [isLocumsOpen, setIsLocumsOpen] = useState(false); // State for the popup 
  const [floatingData, setfloatingData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [staffRoles, setStaffRoles] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [popupContent, setPopupContent] = useState(null); // State for popup content
  //const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 }); // State for popup position
  const [dialogData, setDialogData] = useState(null); // State for dialog data
  const calendarRefs = useRef({}); // Track refs for calendar cells
 const selectedLocationName=useRef();
  const [roleColorMap, setRoleColorMap] = useState({}); // State for role-color mapping
  const [workingLimits, setWorkingLimits] = useState([]); // State for working limits
  const [bankHolidays, setBankHolidays] = useState([]); // State for working limits

 
  const handleDialogClose = () => {
    // Perform any actions needed to refresh the UI
    fetchStaffHolidays(); // Refetch holidays or any other necessary data
    setDialogData(null); // Close the dialog
  };

  const handlePreviousYear = () => {
    setSelectedYear(prevYear => prevYear - 1);
  };

  const handleNextYear = () => {
    setSelectedYear(prevYear => prevYear + 1);
  };

  const handleYearChange = (e) => {
    setSelectedYear(Number(e.target.value));
  };


  const handleLocumsClick = () => {
    setIsLocumsOpen(true); // Open the Locums popup
  };

  const handleCloseLocums = () => {
    setIsLocumsOpen(false); // Close the Locums popup
  };


  const getTextColor = (backgroundColor) => {
    const color = backgroundColor.replace('#', '');
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? 'black' : 'white';
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);

  // Find the selected location by its ID
  const selectedLocationObj = locations.find(location => location._id === e.target.value);

  if (selectedLocationObj) {
    // Assign location.name to selectedLocationName.current
    selectedLocationName.current = selectedLocationObj.name;
  }



    
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleMouseEnter = (data) => {
  //  if (data.holidayEntries.length>0){
   // setfloatingData(data);
   // }
  };
  
  const handlefloatingClose = () => {
    setfloatingData(null);
  };
  

   
  const handleMouseLeave = () => {
   setfloatingData(null);
  };

  const handleCellClick = (dayData) => {
    setDialogData(dayData);
  };
  
  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
  };

  

  const yearOptions = [];
  for (let year = 2024; year <= currentYear + 5; year++) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  
    const transformHolidayData = (data) => {
      return data.map(holiday => {
        const startDate = new Date(holiday.start);
        const endDate = new Date(holiday.end);
    
        const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
      //    hour: '2-digit',
       //   minute: '2-digit',
       //   second: '2-digit',
        //  hour12: false
        };
    
        return {
          ...holiday,
          start: startDate.toLocaleString('en-GB', options), // Convert to local date and time string in dd/mm/yyyy format
          end: endDate.toLocaleString('en-GB', options)    // Convert to local date and time string in dd/mm/yyyy format
        };
      });
    };
    
     
 //   const transformedEntries = transformHolidayData(holidayEntries);
  //  //console.log(transformedEntries);
    

  const parseDateString = (dateString) => {
    const [month, day, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // JavaScript months are zero-based
  };
  

  const calculateCellData = (date) => {
    const dayOfWeek = date.toLocaleDateString('en-GB', { weekday: 'short' });
    const weekNumber = Math.ceil(date.getDate() / 7) === 5 ? 1 : Math.ceil(date.getDate() / 7);
  
    const roles = Array.from(new Set(staffMembers.current.map(member => member.role)));
    let columns = roles.map(role => {
      let totalHours = 0;
      let status = null; // Initialize status early
      let locumused=false;
      // Calculate total hours worked for this role on the specific day
   // Filter staff members based on selectedLocation and role
   const filteredStaff = staffMembers.current.filter(
    member => member.default_location_id === selectedLocation && member.role === role
  );
  
// Iterate through the filtered staff members to calculate total hours
filteredStaff.forEach(member => {
  member.dailyEntries.forEach(entry => {
    if (entry.dayOfWeek === dayOfWeek && entry.weekNumber === weekNumber && entry.startTime !== "00:00") {
      totalHours += entry.hoursworked;
    }
  });
});

    
      // Keep track of whether there were any hours initially at all
      let fullHours = totalHours;
  
      // Subtract hours based on staff holidays
      staffHolidays.forEach(holiday => {
        if (holiday.start <= date.toISOString().split('T')[0] && holiday.end >= date.toISOString().split('T')[0]) {
          if (holiday.role === role) {
            staffMembers.current.forEach(member => {
              if (member._id === holiday.staff_id) {
                member.dailyEntries.forEach(entry => {
                  if (entry.dayOfWeek === dayOfWeek && entry.weekNumber === weekNumber && entry.hoursworked>0) {
                    totalHours -= entry.hoursworked;
                  }
                });
              }
            });
          }
        }
      });
  

      // add hours based on locums
      locums.forEach(locum => {
        if (locum.start <= date.toISOString().split('T')[0] && locum.end >= date.toISOString().split('T')[0]) {
          if (locum.role === role) {
            //calculate how many hours worked
            locumused=true;
            if (isValidTimeFormat(locum.startTime) && isValidTimeFormat(locum.endTime)) {
              totalHours += calculateHours(locum.startTime, locum.endTime);
            } else {
              console.error("Invalid time format for startTime or endTime.");
            }
          }
        }
      });


      //for testing
 
      // If fullHours is 0, no working hours for this role type on this day
      if (fullHours === 0) {
  
        status = -2;
        totalHours = null;
        //console.log(`either fulllhours is 0 or no working hours for tis role on this day...${role} ${totalHours} `);

        return {
          role,
          totalHours,
          status,
          locumused 
        };
      }
  
      // Determine the status based on working limits
      const workingLimitKey = `${role}-${dayOfWeek}`; // Construct the key
      const workingLimit = workingLimits[workingLimitKey]; // Get the corresponding value
   
      // If no working limit is set or minimum is 0, return early with null status
      if (!workingLimit || workingLimit === 0) {
  
        status = -2;
        return {
          role,
          totalHours,
          status,
          locumused 
        };
      }
  
      const optimum=workingLimit*1.5;
   
      // Calculate status based on the working limits
      if (totalHours < workingLimit) {
        status = 0;  // Below minimum
      } else if (totalHours >= workingLimit && totalHours <= optimum) {
        status = 1;  // Between minimum and optimum
      } else if (totalHours > optimum) {
        status = 2;  // Above optimum
      }
  
      //console.log(`calculated outcome is...${role} ${totalHours} ${status}`);

      return {
        role,
        totalHours,
        status,
        locumused 
      };
    });
  
    return columns;
  };
  
  const isValidTimeFormat = (time) => {
    // Regular expression to match HH:MM format
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timePattern.test(time);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 3:
        return 'red'; // Example color for under limits
      case 1:
        return 'lightgreen'; // Light green for within limits
      case 2:
        return '#32CD32'; // mid green for over limits
      default:
        return 'inherit';
    }
  };



  const calculateHours = (startTime, endTime) => {
    // Split the time strings into hours and minutes
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
  
    // Calculate the difference in hours and minutes
    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;
  
    // Find the total difference in minutes
    const totalMinutes = endTotalMinutes - startTotalMinutes;
  
    // Convert the total minutes difference into hours
    const hrs = totalMinutes / 60;
    
    return hrs.toFixed(1);
  };


  const generateMonthData = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const days = [];
    
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
  if (date=='2024-01-01T00:00:00.000Z'){
    const xxx=3;
  }
      // Calculate cell data for the day
      const cellData = calculateCellData(date);
      
      // Initialize the variable for overallStatus
      let overallStatus = 1; // assume perfect.
      let overlimit=0;
      let isAnyLocumUsed = cellData.some(cell => cell.locumused === true);
      // Check for the status in the cellData array
      for (let i = 0; i < cellData.length; i++) {

 
        if (cellData[i].status === 0) {
          overallStatus = 3; // at least one is under limit so abandon ship
          break; // No need to check further
        }
        if (cellData[i].status === 2) {
          overlimit = 1; // just note that at least one is over the limit
          
        }
         if (cellData[i].status <0) {
          overallStatus = -1; // at least one doesnt have a range, so abandon ship. 
          break;
        }
      }
      
//if outcome is -1 or 0 then no need for any change.
// if outcome is  1, so everyone ok, make it 2 if overlimit is set as well..because at least one entry is oversupplied
if (overallStatus==1 && overlimit==1){
    overallStatus=2;
}

       // Push the day's data with the overallStatus
      days.push({
        day,
        dayOfWeek: date.getDay(),
        date,
        cellData,
        overallStatus,
        isAnyLocumUsed // Pass the computed overallStatus
      
      });
    }
    
    return days;
  };
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const months = monthNames.map((monthName, index) => {
    const days = generateMonthData(selectedYear, index);
    return { monthName, days };
  });




  const fetchWorkingLimits = async () => {
    try {
      const workingLimitsUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/workinglimits?locationId=${encodeURIComponent(selectedLocation)}`;
      const response = await axios.get(workingLimitsUrl);
  
      const workingLimitData = response.data;

      // Check if working limit data exists for the selected location
     
          // Extract the 'data' array from the first element of the response
          const extractedData = workingLimitData[0].data;
      
          // Convert the extracted 'data' array into a key-pair array
          const newGridData = {};
          extractedData.forEach(item => {
              const key = `${item.role}-${item.day}`; // Create a key based on role and day
              newGridData[key] = item.value; // Assign the value to the key, ignoring _id
          });
      
   
      setWorkingLimits(newGridData);
    } catch (error) {
      // console.error('Failed to load working limits:', error);
    }
  };



  const fetchLocums = async () => {
    try {
 
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/locums?location_id=${encodeURIComponent(selectedLocation)}`;
      const response = await axios.get(staffUrl);    
//      const filtered = response.data.filter(
  //      (locum) => new Date(locum.start) <= new Date(dialogDate) && new Date(locum.end) >= new Date(dialogDate)
   //   );
      
  setLocums(response.data); 

} catch (error) {
  console.error('Error fetching locums:', error);
}
};



  const fetchStaffMembers = async () => {
    try {
      const staffUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff?default_location_id=${selectedLocation}`;
      const staffResponse = await axios.get(staffUrl);
    staffMembers.current= staffResponse.data;
   //   //////console.log('Successfully fetched staff members:', staffResponse.data);
    } catch (error) {
      //console.error('Failed to load staff members:', error);
    }
  };

  const fetchStaffHolidays = async () => {
    //////console.log('Attempting to fetch staff holidays for year:', selectedYear);
  
    try {
      const roleParam = selectedRole ? `&role=${encodeURIComponent(selectedRole)}` : '';
      const locationParam = selectedLocation ? `&organisation_id=${encodeURIComponent(selectedLocation)}` : '';
      
     const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday?year=${selectedYear}${roleParam}${locationParam}`;
  //const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday`;
      const response = await axios.get(holidaysUrl);
      //////console.log('Successfully fetched staff holidays:', response.data);
  
      // Transform response data to handle UTC dates
      const transformedData = transformHolidayData(response.data);
  
      //////console.log('Transformed Data:', transformedData);
      setStaffHolidays(response.data);
      let x=1;
    } catch (error) {
      //console.error(`Failed to load staff holidays for ${selectedYear}:`, error);
    }
  };




  const fetchBankHolidays = async () => {
    //////console.log('Attempting to fetch staff holidays for year:', selectedYear);
  
    try {
       const locationParam = selectedLocation ? `&location_id=${encodeURIComponent(selectedLocation)}` : '';
      
     const bankholidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/bank_holidays?year=${selectedYear}${locationParam}`;
  //const holidaysUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/staff_holiday`;
      const response = await axios.get(bankholidaysUrl);
      //////console.log('Successfully fetched staff holidays:', response.data);
  
      // Transform response data to handle UTC dates
//      const transformedData = transformHolidayData(response.data);
  
      //////console.log('Transformed Data:', transformedData);
      setBankHolidays(response.data);
       
    } catch (error) {
      //console.error(`Failed to load staff holidays for ${selectedYear}:`, error);
    }
  };




  const fetchLocations = async (orgId) => {
    try {
      const locationResponse = await axios.get('https://heuristic-cray.194-76-27-167.plesk.page/api/locations');
      const filteredLocations = locationResponse.data.filter(location => location.organisation === orgId);
      setLocations(filteredLocations); // Set t
 
 

      // Set selectedLocation to the _id of the first item in the locations array
      if (filteredLocations.length > 0) {
        setSelectedLocation(filteredLocations[0]._id);
        // Find the selected location by its ID
         selectedLocationName.current = filteredLocations[0].name;
       }

      // Set selectedYear to the current year
      if (selectedYear==''){
      const currentYear = new Date().getFullYear().toString();
      setSelectedYear(currentYear);
    }


    } catch (error) {
      //console.error('Failed to load locations:', error);
    }
  };

  const fetchStaffRoles = async () => {
    try {
      const rolesUrl = 'https://heuristic-cray.194-76-27-167.plesk.page/api/staff_roles';
      const response = await axios.get(rolesUrl);
      // //console.log('Successfully fetched staff roles:', response.data);
  
      // Create a role-color map from the response
      const roleMap = response.data.reduce((map, role) => {
        // Trim leading and trailing spaces from role names and ensure they are strings
        const trimmedRole = role.role.trim();
        const color = role.colour; // Assuming the color field is named 'colour'
        if (trimmedRole && color) {
          map[trimmedRole] = color;
        }
        return map;
      }, {});
  
      // Set the roles for dropdown, trimming any extra spaces
      const trimmedRoles = response.data.map(role => role.role.trim());
      setStaffRoles(trimmedRoles);
  
      // Set the role-color map
      setRoleColorMap(roleMap);
    } catch (error) {
      // console.error('Failed to load staff roles:', error);
    }
  };
  
 
  useEffect(() => {
    // Fetch locations first
    const loadData = async () => {
      await fetchBankHolidays();
      await fetchLocations(organisationId);
      await fetchStaffRoles();
    //  await fetchWorkingLimits();

    };

    loadData();
  }, []);

  useEffect(() => {
    // Run other fetch routines when parameters change
    if (selectedYear && selectedLocation) {
      const fetchData = async () => {
        fetchWorkingLimits();
        await fetchStaffMembers();
        await fetchStaffHolidays();
        await fetchLocums();
      };

      fetchData();
    }
  }, [selectedYear, selectedLocation]);


  return (
    <div className="holiday-planner-container">
      <div className="planner-header">
        <h2 className="planner-title">Workforce Planner</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '20px' }}>
        <button onClick={handleLocumsClick}>Locums</button>
</div>

        <div className="year-selector">
          <select value={selectedLocation} onChange={handleLocationChange}>
            {locations.map(location => (
              <option key={location.id} value={location._id}>
                {location.name}
              </option>
            ))}
          </select>
          <select value={selectedYear} onChange={handleYearChange}>
            {yearOptions}
          </select>
           <button onClick={handlePreviousYear}>Previous Year</button>
          <button onClick={handleNextYear}>Next Year</button>
        </div>
      </div>
      <div className="calendar">
    

      {months.map((month, index) => (
  <div key={index} className="month-row">
    <div className="month-name">{month.monthName}</div>
    {month.days.map((dayData, dayIndex) => (
      <div
        key={dayIndex}
        ref={el => calendarRefs.current[`day-${dayIndex}`] = el} // Set ref
        className={`calendar-day ${dayData.dayOfWeek === 0 || dayData.dayOfWeek === 6 ? 'weekend' : 'weekday'}`} // Add weekend or weekday class
        style={{
          backgroundColor: dayData.overallStatus ? getStatusColor(dayData.overallStatus) : 'inherit', // Conditionally set background color
          border: dayData.isAnyLocumUsed ? '5px solid darkblue' : '1px solid black' // Set a thicker border based on isLocumUsed
      }}
        onMouseEnter={(event) => handleMouseEnter(dayData)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleCellClick(dayData)}
        tabIndex="0"
      >
        <span className="day-name">{dayNames[dayData.dayOfWeek]}</span>
        <span className="day-number">{dayData.day}</span>
        {/* Display the contents of dayData on the screen */}
        <div className="day-data-details">
          {/*JSON.stringify(dayData)} {/* Display dayData as a string */}
        </div>
      </div>
    ))}
  </div>
))}

       </div>


   
      {dialogData && (
  <PlanningDialog
    open={Boolean(dialogData)}
    onClose={handleDialogClose}
    data={dialogData}          // Pass dayData directly
    workingLimits={workingLimits} // Pass workingLimits
    selectedLocation={selectedLocation} // Pass selectedLocation
    selectedLocationName={selectedLocationName.current}
    staffMembers={staffMembers.current} // Access staffMembers through .current
  />
)}

<FullLocums selectedLocation={selectedLocation} isOpen={isLocumsOpen} onClose={handleCloseLocums} />


</div> 
  );
};

export default Planning;
